import React from 'react'
import moment from 'moment'
import 'moment/locale/ja'

import 'rc-calendar/assets/index.css'
import Calendar from 'rc-calendar'
import DatePicker from 'rc-calendar/lib/Picker'
import 'rc-time-picker/assets/index.css'
import TimePickerPanel from 'rc-time-picker/lib/Panel'
import ja from 'rc-calendar/lib/locale/ja_JP'

import Constants from '../../common/components/constants.jsx'
import ajax from '../../common/components/ajax.jsx'

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    Radio,
    FormInput,
    FormField,
    FormSelect,
} from 'elemental-react16'

import 'sass/topics.scss'
import {isFullScreenCategory} from "./Utils";

export default class TopicForm extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        const authKey = (typeof props.TOPIC_ID !== 'undefined' && !!props.TOPIC_ID) ? 'EDIT_TOPIC' : 'CREATE_TOPIC';
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty(authKey) &&
            props.auth.authorities[authKey]
        );
        const patrolAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('PATROL_TOPIC') &&
            props.auth.authorities.PATROL_TOPIC
        );


        this.state = {
            categories: props.categories,

            editAuthorized: editAuthorized,
            patrolAuthorized: patrolAuthorized,

            alertTitle: '',
            alertBody: '',
            alertModalIsOpen: false,

            role: props.role,

            isOpen: props.isOpen,

            headerText: props.isNew ? '新規トピックの追加' : 'トピックの編集',
            submitText: props.isNew ? '追加' : '保存',

            TOPIC_ID: props.TOPIC_ID,
            CATEGORY_ID: props.CATEGORY_ID,
            TOPIC_TITLE: props.TOPIC_TITLE,
            TOPIC_BODY: props.TOPIC_BODY,
            TOPIC_AUTHOR_NAME: props.TOPIC_AUTHOR_NAME,
            TOPIC_AVAILABLE_FROM: props.TOPIC_AVAILABLE_FROM,
            TOPIC_AVAILABLE_TO: props.TOPIC_AVAILABLE_TO,
            MEDIAS: props.MEDIAS,
            PATROLLED: props.PATROLLED,
            TOPIC_CREATED: props.TOPIC_CREATED,

            youtubeUrl: '',
            youtubeModalIsOpen: false,

            is_full_screen_category: isFullScreenCategory(props.categories, props.CATEGORY_ID)
        };
    }


    componentWillReceiveProps(props) {
        const authKey = (typeof props.TOPIC_ID !== 'undefined' && !!props.TOPIC_ID) ? 'EDIT_TOPIC' : 'CREATE_TOPIC';
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty(authKey) &&
            props.auth.authorities[authKey]
        );
        const patrolAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('PATROL_TOPIC') &&
            props.auth.authorities.PATROL_TOPIC
        );

        this.setState({
            categories: props.categories,

            editAuthorized: editAuthorized,
            patrolAuthorized: patrolAuthorized,

            isOpen: props.isOpen,

            headerText: props.isNew ? '新規トピックの追加' : 'トピックの編集',
            submitText: props.isNew ? '追加' : '保存',

            TOPIC_ID: props.TOPIC_ID,
            CATEGORY_ID: props.CATEGORY_ID,
            TOPIC_TITLE: props.TOPIC_TITLE,
            TOPIC_BODY: props.TOPIC_BODY,
            TOPIC_AUTHOR_NAME: props.TOPIC_AUTHOR_NAME,
            TOPIC_AVAILABLE_FROM: props.TOPIC_AVAILABLE_FROM,
            TOPIC_AVAILABLE_TO: props.TOPIC_AVAILABLE_TO,
            MEDIAS: props.MEDIAS,
            PATROLLED: props.PATROLLED,
            TOPIC_CREATED: props.TOPIC_CREATED,

            is_full_screen_category: isFullScreenCategory(props.categories, props.CATEGORY_ID)
        });
    }

    toggleAlertModal() {
        this.setState({alertModalIsOpen: !this.state.alertModalIsOpen});
    }

    toggleModal() {
        this.props.toggleModal();
    }

    onChangeTitle(val) {
        if (!this.state.editAuthorized) return;
        this.props.onChangeTitle(val);
    }

    onChangeAuthor(val) {
        if (!this.state.editAuthorized) return;
        this.props.onChangeAuthor(val);
    }

    onChangeBody(val) {
        if (!this.state.editAuthorized) return;
        this.props.onChangeBody(val);
    }

    onChangeFrom(val) {
        if (!this.state.editAuthorized) return;
        this.props.onChangeFrom(val);
    }

    onChangeTo(val) {
        if (!this.state.editAuthorized) return;
        this.props.onChangeTo(val);
    }

    onChangeCategory(val) {
        if (!this.state.editAuthorized) return;
        this.props.onChangeCategory(parseInt(val, 10));
    }

    onChangePatrolled(val) {
        if (!this.state.editAuthorized && !this.state.patrolAuthorized) return;
        this.props.onChangePatrolled(val);
    }

    onSubmit(form) {
        if (!this.state.editAuthorized && !this.state.patrolAuthorized) return;
        this.props.onSubmit(form);
    }

    disabledDateFrom(current) {
        if (this && this.hasOwnProperty('state') && this.state.hasOwnProperty('TOPIC_AVAILABLE_TO')) {
            return this.state.TOPIC_AVAILABLE_TO <= current;
        }
    }

    disabledTimeFrom(current) {

    }

    disabledDateTo(current) {
        if (this && this.hasOwnProperty('state') && this.state.hasOwnProperty('TOPIC_AVAILABLE_FROM')) {
            return current <= this.state.TOPIC_AVAILABLE_FROM;
        }
    }

    disabledTimeTo(current) {

    }

    onOpenChange(open) {
        console.log('open', open);
    }

    onMediaTitleChange(val, index) {
        if (!this.state.editAuthorized) return;

        let medias = this.state.MEDIAS.map((media, i) => {
            if (i !== index) return media;
            media.TITLE = val;
            return media;
        });
        this.props.onChangeMedias(medias);
    }

    onMediaCommentChange(val, index) {
        if (!this.state.editAuthorized) return;

        let medias = this.state.MEDIAS.map((media, i) => {
            if (i !== index) return media;
            media.COMMENT = val;
            return media;
        });
        this.props.onChangeMedias(medias);
    }

    onMediaSizeChange(val, index) {
        if (!this.state.editAuthorized) return;

        let medias = this.state.MEDIAS.map((media, i) => {
            if (i !== index) return media;
            media.DISPLAY_SIZE = val;
            return media;
        });
        this.props.onChangeMedias(medias);
    }

    onMediaRemove(index) {
        if (!this.state.editAuthorized) return;

        let medias = [];
        if (this.state.MEDIAS[index].TYPE !== 'video') {
            medias = this.state.MEDIAS.filter((media, i) => {
                return i !== index;
            });
        }
        this.props.onChangeMedias(medias);
    }

    onAddImageButtonClicked() {
        if (!this.state.editAuthorized) return;

        if (this.state.MEDIAS.length > 0 && this.state.MEDIAS[0].TYPE === 'video') {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '画像と動画を同時に添付することはできません。画像を添付するには、動画の添付を解除してください。',
                alertModalIsOpen: true
            });
            return;
        }
        document.getElementById('fileForm').click();
    }


    onAddPdfButtonClicked() {
        if (!this.state.editAuthorized) return;

        if (this.state.MEDIAS.length > 0 && this.state.MEDIAS[0].TYPE === 'video') {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '画像と動画を同時に添付することはできません。画像を添付するには、動画の添付を解除してください。',
                alertModalIsOpen: true
            });
            return;
        }
        if (this.state.MEDIAS.length > 0 && this.state.MEDIAS[0].TYPE === 'image') {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '画像とPDFを同時に添付することはできません。PDFを添付するには、画像の添付を解除してください。',
                alertModalIsOpen: true
            });
            return;
        }
        document.getElementById('fileFormPdf').click();
    }


    onAddImage(files) {
        if (!this.state.editAuthorized) return;

        let medias = this.state.MEDIAS;

        // fileの数だけfalseを入れた配列を作る
        let counter = []

        let index = 0;
        for (const file of files) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                if (!e.target.error) {
                    counter[e.target.index] = {
                        'TITLE': file.name,
                        'TYPE': 'image',
                        'BASE64': e.target.result,
                        'COMMENT': '',
                        'DISPLAY_SIZE': 'LARGE'
                    };
                }

                console.log('counter', counter);
                const notyet = counter.filter((val) => {
                    return typeof val === 'boolean';
                }).length > 0;
                if (!notyet) {
                    counter.forEach((val) => {
                        if (typeof val !== 'boolean') {
                            medias.push(val);
                        }
                    });
                    this.props.onChangeMedias(medias);
                }
            };
            reader.index = index;
            reader.readAsDataURL(file);

            counter.push(false);
            index++;
        }
    }

    onAddPdf(files) {
        if (!this.state.editAuthorized) return;

        let medias = [];

        // fileの数だけfalseを入れた配列を作る
        let counter = []

        let index = 0;
        for (const file of files) {
            const reader = new FileReader();
            reader.onloadend = (e) => {
                if (!e.target.error) {
                    counter[e.target.index] = {
                        'TITLE': file.name,
                        'TYPE': this.state.pdf_type,
                        'BASE64': e.target.result,
                        'COMMENT': '',
                        'DISPLAY_SIZE': 'LARGE'
                    };
                }
                const notyet = counter.filter((val) => {
                    return typeof val === 'boolean';
                }).length > 0;
                if (!notyet) {
                    counter.forEach((val) => {
                        if (typeof val !== 'boolean') {
                            medias.push(val);
                        }
                    });
                    this.props.onChangeMedias(medias);
                }
            };
            reader.index = index;
            reader.readAsDataURL(file);

            counter.push(false);
            index++;
        }
        this.togglePdfModal();
    }

    toggleYoutubeModal() {
        if (!this.state.editAuthorized) return;

        if (this.state.MEDIAS.length > 0) {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '動画を添付する場合、他に画像や動画を同時に添付することはできません。他のメディアの添付を解除してください。',
                alertModalIsOpen: true
            });
            return;
        }
        this.setState({youtubeModalIsOpen: !this.state.youtubeModalIsOpen});
    }

    onChangeYouTubeURL(val) {
        this.setState({youtubeUrl: val});
    }

    onDecideYouTubeURL() {
        const YOUTUBE_API_KEY = process.env.NODE_ENV === 'production' ? ' AIzaSyAbPZNAuwvlF8Qk2Y69z94_OfJZ2HanhKU' : "AIzaSyAW7FrHNJgjq91mgUGhEppGNiSyMuDagu8";
        const YOUTUBE_VIDEO_ID = this.parse_youtube_url(this.state.youtubeUrl);
        if (!YOUTUBE_VIDEO_ID) {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '動画が見つかりませんでした',
                alertModalIsOpen: true
            });
            return;
        }

        const YOUTUBE_API_URL = `https://www.googleapis.com/youtube/v3/videos?key=${YOUTUBE_API_KEY}&part=snippet&id=${YOUTUBE_VIDEO_ID}`;


        ajax.get(
            YOUTUBE_API_URL,
            (successJson) => {
                if (typeof successJson.items === 'undefined' || successJson.items.length === 0) {
                    this.setState({
                        alertTitle: 'メディア添付エラー',
                        alertBody: '動画が見つかりませんでした',
                        alertModalIsOpen: true
                    });
                    return;
                }

                const snippet = successJson.items[0].snippet;

                this.toggleYoutubeModal();
                this.props.onChangeMedias([{
                    'TITLE': snippet.title || '',
                    'TYPE': 'video',
                    'BASE64': snippet.thumbnails.medium.url,
                    'URL': `https://www.youtube.com/watch?v=${YOUTUBE_VIDEO_ID}`,
                    'COMMENT': snippet.description || '',
                    'DISPLAY_SIZE': 'LARGE'
                }]);
            },
            (errorJson) => {
                console.log(errorJson);
            },
            true
        );

    }

    parse_youtube_url(youtubeUrl) {
        try {
            const url = new URL(youtubeUrl);
            switch (url.hostname) {
                case 'www.youtube.com':
                    var queries = url.search.substring(1).split('&');
                    for (var i = 0; i < queries.length; i++) {
                        var keyval = queries[i].split('=');
                        if (keyval[0] === 'v') return keyval[1] || '';
                    }
                    break;
                case 'www.youtube-nocookie.com':
                case 'youtu.be':
                    var dirs = url.pathname.split('/');
                    return dirs[dirs.length - 1];
                default:
                    return '';
            }
            return '';
        } catch (e) {
            return '';
        }
    }

    togglePdfModal() {
        if (!this.state.editAuthorized) return;

        if (this.state.MEDIAS.length > 0 && this.state.MEDIAS[0].TYPE === 'video') {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '画像と動画を同時に添付することはできません。画像を添付するには、動画の添付を解除してください。',
                alertModalIsOpen: true
            });
            return;
        }
        if (this.state.MEDIAS.length > 0 && this.state.MEDIAS[0].TYPE === 'image') {
            this.setState({
                alertTitle: 'メディア添付エラー',
                alertBody: '画像とPDFを同時に添付することはできません。PDFを添付するには、画像の添付を解除してください。',
                alertModalIsOpen: true
            });
            return;
        }
        this.setState({pdfModalIsOpen: !this.state.pdfModalIsOpen});
    }

    render() {
        // 画像サイズ選択セレクトボックス
        const mediaDisplaySizeOptions = [
            {value: 'LARGE', label: '大'},
            {value: 'MEDIUM', label: '中'},
            {value: 'SMALL', label: '小'},
        ];


        console.log("rerend", this.state.is_full_screen_category)

        // カテゴリ選択セレクトボックス
        let categorySelectFormOptions = [];
        if (typeof this.state.categories !== 'undefined' && this.state.categories.length > 0) {
            // FormSelect は数値を受け付けないので文字列にする
            categorySelectFormOptions = this.state.categories.map((category) => {
                console.log(category);
                return {
                    label: category.CATEGORY_NAME,
                    value: '' + category.CATEGORY_ID
                };
            });
        }

        let editImage;
        if (typeof this.state.MEDIAS !== 'undefined') {
            if (this.state.MEDIAS.length) {

                if (this.state.MEDIAS[0].TYPE === "pdf_normal" || this.state.MEDIAS[0].TYPE === "pdf_spread") {
                    editImage = (
                        <ul className="thumbnails">
                            {this.state.MEDIAS.map((media, index) => {
                                return (
                                    <li key={index}>
                                        <div className="info">
                                            <FormInput
                                                disabled={!this.state.editAuthorized}
                                                placeholder="タイトル"
                                                value={media.TITLE.replace(/\\n/g, '\n')}
                                                onChange={(evt) => {
                                                    this.onMediaTitleChange(evt.currentTarget.value, index);
                                                }}/>

                                            <FormInput
                                                disabled={!this.state.editAuthorized}
                                                multiline
                                                placeholder="コメント"
                                                value={media.COMMENT.replace(/\\n/g, '\n')}
                                                onChange={(evt) => {
                                                    this.onMediaCommentChange(evt.currentTarget.value, index);
                                                }}/>

                                            <div className="bottom">
                                                <FormSelect
                                                    disabled={true}
                                                    options={mediaDisplaySizeOptions}
                                                    firstOption='表示サイズを選択'
                                                    value={media.DISPLAY_SIZE ? media.DISPLAY_SIZE : "LARGE"}
                                                    onChange={(val) => {
                                                        this.onMediaSizeChange(val, index);
                                                    }}/>

                                                <div className="buttons">
                                                    <Button
                                                        type="link-cancel"
                                                        disabled={!this.state.editAuthorized}
                                                        onClick={(evt) => {
                                                            this.onMediaRemove(index);
                                                        }}
                                                    >削除</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    );
                } else if (this.state.MEDIAS.length === 2 && (this.state.MEDIAS[0].TYPE === 'video' || this.state.MEDIAS[1].TYPE === 'video')) {
                    //ビデオの場合
                    const videosIndex = this.state.MEDIAS[0].TYPE === 'video' ? 0 : 1;
                    const url = this.state.MEDIAS[videosIndex ? 0 : 1].URL.replace('getimage-origin', 'getimage(dh=150,dw=150,pt=ptss)');
                    const media = this.state.MEDIAS[videosIndex];
                    editImage = (
                        <ul className="thumbnails">
                            <li>
                                <div className="drawer" style={{backgroundImage: `url('${url}')`}}></div>
                                <div className="info">
                                    <FormInput
                                        disabled={!this.state.editAuthorized}
                                        placeholder="タイトル"
                                        value={media.TITLE.replace(/\\n/g, '\n')}
                                        onChange={(evt) => {
                                            this.onMediaTitleChange(evt.currentTarget.value, videosIndex);
                                        }}/>

                                    <FormInput
                                        disabled={!this.state.editAuthorized}
                                        multiline
                                        placeholder="コメント"
                                        value={media.COMMENT.replace(/\\n/g, '\n')}
                                        onChange={(evt) => {
                                            this.onMediaCommentChange(evt.currentTarget.value, videosIndex);
                                        }}/>

                                    <div className="bottom">
                                        <FormSelect
                                            disabled={!this.state.editAuthorized || media.TYPE === 'video'}
                                            options={mediaDisplaySizeOptions}
                                            firstOption='表示サイズを選択'
                                            value={media.DISPLAY_SIZE}
                                            onChange={(val) => {
                                                this.onMediaSizeChange(val, videosIndex);
                                            }}/>

                                        <div className="buttons">
                                            <cite>URL: <a href={media.URL} target="_blank">{media.URL}</a></cite>

                                            <Button
                                                type="link-cancel"
                                                disabled={!this.state.editAuthorized}
                                                onClick={(evt) => {
                                                    this.onMediaRemove(videosIndex);
                                                }}
                                            >削除</Button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    );
                } else {
                    editImage = (
                        <ul className="thumbnails">
                            {this.state.MEDIAS.map((media, index) => {
                                let url = '';
                                if (media.hasOwnProperty('URL')) {
                                    url = media.URL.replace('getimage-origin', 'getimage(dh=150,dw=150,pt=ptss)');
                                }
                                if (media.hasOwnProperty('BASE64')) {
                                    url = media.BASE64;
                                }

                                return (
                                    <li key={index}>
                                        <div className="drawer" style={{backgroundImage: `url('${url}')`}}></div>
                                        <div className="info">
                                            <FormInput
                                                disabled={!this.state.editAuthorized}
                                                placeholder="タイトル"
                                                value={media.TITLE.replace(/\\n/g, '\n')}
                                                onChange={(evt) => {
                                                    this.onMediaTitleChange(evt.currentTarget.value, index);
                                                }}/>

                                            <FormInput
                                                disabled={!this.state.editAuthorized}
                                                multiline
                                                placeholder="コメント"
                                                value={media.COMMENT.replace(/\\n/g, '\n')}
                                                onChange={(evt) => {
                                                    this.onMediaCommentChange(evt.currentTarget.value, index);
                                                }}/>

                                            <div className="bottom">
                                                <FormSelect
                                                    disabled={!this.state.editAuthorized || media.TYPE === 'video'}
                                                    options={mediaDisplaySizeOptions}
                                                    firstOption='表示サイズを選択'
                                                    value={media.DISPLAY_SIZE}
                                                    onChange={(val) => {
                                                        this.onMediaSizeChange(val, index);
                                                    }}/>

                                                <div className="buttons">
                                                    <Button
                                                        type="link-text"
                                                        className="download"
                                                        onClick={(evt) => {
                                                            const a = document.createElement('a');
                                                            let filename = media.TITLE;
                                                            if (!/\.jpe?g/i.test(filename)) {
                                                                filename += '.jpg';
                                                            }

                                                            a.href = media.URL.replace('-origin', '()');
                                                            a.download = filename;
                                                            a.click();
                                                        }}
                                                    >ダウンロード</Button>

                                                    <Button
                                                        type="link-cancel"
                                                        disabled={!this.state.editAuthorized}
                                                        onClick={(evt) => {
                                                            this.onMediaRemove(index);
                                                        }}
                                                    >削除</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    );
                }
            }
        }

        const fromCalendar = (
            <Calendar
                locale={ja}
                formatter={Constants.DateFormats.formDate}
                timePicker={(<TimePickerPanel/>)}
                showDateInput={false}
                disabledDate={this.disabledDateFrom}
                disabledTime={this.disabledTimeFrom}
                showOk={false}/>
        );

        const toCalendar = (
            <Calendar
                locale={ja}
                formatter={Constants.DateFormats.formDate}
                timePicker={(<TimePickerPanel/>)}
                showDateInput={false}
                disabledDate={this.disabledDateTo}
                disabledTime={this.disabledTimeTo}
                showOk={false}/>
        );

        return (
            <Modal width="large" isOpen={this.state.isOpen} onCancel={() => {
                this.toggleModal();
            }} backdropClosesModal>
                <ModalHeader text={this.state.headerText} showCloseButton onClose={() => {
                    this.toggleModal();
                }}/>
                <ModalBody>
                    <div className="datepicker_wrapper">
                        <div>
                            <label className="FormLabel">配信開始日時</label>

                            <div style={{
                                boxSizing: 'border-box',
                                position: 'relative',
                                display: 'block',
                                lineHeight: 1.5,
                                marginBottom: 22,
                            }}>
                                <DatePicker
                                    disabled={!this.state.editAuthorized}
                                    animation="slide-up"
                                    value={this.state.TOPIC_AVAILABLE_FROM}
                                    calendar={fromCalendar}
                                    onChange={(val) => {
                                        this.onChangeFrom(val);
                                    }}
                                >
                                    {
                                        ({value}) => {
                                            return (
                                                <span tabIndex="0">
                                                <input
                                                    style={{width: 250}}
                                                    readOnly
                                                    tabIndex="-1"
                                                    className="ant-calendar-picker-input ant-input"
                                                    value={value ? value.format(Constants.DateFormats.long) : ''}
                                                />
                                            </span>
                                            );
                                        }
                                    }
                                </DatePicker>
                            </div>
                        </div>
                        <div>
                            <label className="FormLabel">配信終了日時</label>

                            <div style={{
                                boxSizing: 'border-box',
                                position: 'relative',
                                display: 'block',
                                lineHeight: 1.5,
                                marginBottom: 22,
                            }}>
                                <DatePicker
                                    disabled={!this.state.editAuthorized}
                                    animation="slide-up"
                                    value={this.state.TOPIC_AVAILABLE_TO}
                                    calendar={toCalendar}
                                    onChange={(val) => {
                                        this.onChangeTo(val);
                                    }}
                                >
                                    {
                                        ({value}) => {
                                            return (
                                                <span tabIndex="0">
                                                <input
                                                    style={{width: 250}}
                                                    readOnly
                                                    tabIndex="-1"
                                                    className="ant-calendar-picker-input ant-input"
                                                    value={value ? value.format(Constants.DateFormats.long) : ''}
                                                />
                                            </span>
                                            );
                                        }
                                    }
                                </DatePicker>
                            </div>
                        </div>
                        <div>
                            <label className="FormLabel">トピック作成日時</label>
                            <div className="date_box">{
                                (!!this.state.TOPIC_CREATED) ? moment(this.state.TOPIC_CREATED).format(Constants.DateFormats.long) : '-'
                            }</div>
                        </div>
                    </div>

                    <Form component="form">
                        <FormSelect
                            disabled={!this.state.editAuthorized}
                            label="カテゴリ"
                            options={categorySelectFormOptions}
                            firstOption="カテゴリを選択"
                            onChange={(val) => {
                                this.onChangeCategory(val);
                            }}
                            value={'' + this.state.CATEGORY_ID}/>

                        {this.state.is_full_screen_category ? null :
                            <FormField label="タイトル（100字以内）" htmlFor="supported-controls-input">
                                <FormInput
                                    disabled={!this.state.editAuthorized}
                                    placeholder="タイトルを入力してください"
                                    value={this.state.TOPIC_TITLE}
                                    name="supported-controls-input"
                                    onChange={(evt) => {
                                        this.onChangeTitle(evt.currentTarget.value);
                                    }}/>
                            </FormField>
                        }


                        <FormField label="作成者名" htmlFor="supported-controls-input">
                            <FormInput
                                disabled={!this.state.editAuthorized}
                                placeholder="作成者名を入力してください"
                                value={this.state.TOPIC_AUTHOR_NAME}
                                name="supported-controls-input"
                                onChange={(evt) => {
                                    this.onChangeAuthor(evt.currentTarget.value);
                                }}/>
                        </FormField>

                        {this.state.is_full_screen_category ? null :
                            <FormField label="本文" htmlFor="supported-controls-input">
                                <FormInput
                                    disabled={!this.state.editAuthorized}
                                    placeholder="本文を入力してください"
                                    value={this.state.TOPIC_BODY.replace(/\\n/g, '\n')}
                                    name="body"
                                    className="editBodyForm"
                                    multiline
                                    onChange={(evt) => {
                                        this.onChangeBody(evt.currentTarget.value);
                                    }}/>
                            </FormField>
                        }

                        <FormSelect
                            disabled={!this.state.editAuthorized && !this.state.patrolAuthorized}
                            label="トピックの承認状態"
                            options={[
                                {value: 'NOTYET', label: '未確認'},
                                {value: 'ACCEPTED', label: '承認'},
                                {value: 'REJECTED', label: '却下'},
                            ]}
                            firstOption='承認状態を選択'
                            onChange={(val) => {
                                this.onChangePatrolled(val);
                            }}
                            value={this.state.PATROLLED}/>

                        <div className="imagepicker_wrapper">
                            <FormField label="添付画像 (JPEG PNG GIF 形式 15MB以内）">
                                <Button disabled={!this.state.editAuthorized} onClick={() => {
                                    this.onAddImageButtonClicked();
                                }}>画像を添付する</Button>
                                <Button disabled={!this.state.editAuthorized} onClick={() => {
                                    this.toggleYoutubeModal();
                                }}>動画を添付する</Button>
                                <Button disabled={!this.state.editAuthorized} onClick={() => {
                                    this.togglePdfModal();
                                }}>PDFを添付する</Button>
                            </FormField>

                            {editImage}
                        </div>
                    </Form>

                    <Form style={{display: 'none'}}>
                        <input disabled={!this.state.editAuthorized} id="fileForm" type="file" multiple accept="image/*"
                               onChange={(evt) => {
                                   this.onAddImage(evt.currentTarget.files);
                                   evt.currentTarget.parentElement.reset();
                               }}/>
                    </Form>
                    <Form style={{display: 'none'}}>
                        <input disabled={!this.state.editAuthorized} id="fileFormPdf" type="file"
                               accept="application/pdf" onChange={(evt) => {
                            this.onAddPdf(evt.currentTarget.files);
                            evt.currentTarget.parentElement.reset();
                        }}/>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button type="primary" style={{width: '150px'}} onClick={() => {
                        this.onSubmit();
                    }}>{this.state.submitText}</Button>
                    <Button type="link-cancel" style={{width: '150px'}} onClick={() => {
                        this.props.onReset();
                        this.toggleModal();
                    }}>キャンセル</Button>
                </ModalFooter>

                <Modal isOpen={this.state.youtubeModalIsOpen} onCancel={() => {
                    this.toggleYoutubeModal();
                }} backdropClosesModal>
                    <ModalHeader text="YouTubeの動画を添付する" showCloseButton onClose={() => {
                        this.toggleYoutubeModal();
                    }}/>
                    <ModalBody>
                        <FormInput placeholder="動画のURLを入力してください" value={this.state.youtubeUrl} onChange={(evt) => {
                            this.onChangeYouTubeURL(evt.currentTarget.value);
                        }}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="primary" style={{width: '150px'}} onClick={() => {
                            this.onDecideYouTubeURL();
                        }}>決定</Button>
                        <Button type="link-cancel" style={{width: '150px'}} onClick={() => {
                            this.toggleYoutubeModal();
                        }}>キャンセル</Button>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={this.state.pdfModalIsOpen} onCancel={() => {
                    this.togglePdfModal();
                }} backdropClosesModal>
                    <ModalHeader text="PDFを添付する" showCloseButton onClose={() => {
                        this.togglePdfModal();
                    }}/>
                    <ModalBody>
                        <FormField label="PDFのタイプを選択してください" onChange={(e) => {
                            this.setState({pdf_type: e.target.value})
                        }}>
                            <div className="inline-controls">
                                <Radio name="inline_radios" label="通常" value={"pdf_normal"}/>
                                <Radio name="inline_radios" label="見開き" value={"pdf_spread"}/>
                            </div>
                        </FormField>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="primary" style={{width: '150px'}} onClick={() => {
                            this.onAddPdfButtonClicked();
                        }}>決定</Button>
                        <Button type="link-cancel" style={{width: '150px'}} onClick={() => {
                            this.togglePdfModal();
                        }}>キャンセル</Button>
                    </ModalFooter>
                </Modal>


                <Modal width="small" isOpen={this.state.alertModalIsOpen} onCancel={() => {
                    this.toggleAlertModal();
                }} backdropClosesModal>
                    <ModalHeader text={this.state.alertTitle} showCloseButton onClose={() => {
                        this.toggleAlertModal();
                    }}/>
                    <ModalBody>{this.state.alertBody}</ModalBody>
                </Modal>
            </Modal>
        );
    }
}


TopicForm.defaultProps = {
    TOPIC_ID: 0,
    CATEGORY_ID: 0,
    TOPIC_TITLE: '',
    TOPIC_BODY: '',
    TOPIC_AUTHOR_NAME: '',
    TOPIC_AVAILABLE_FROM: moment(),
    TOPIC_AVAILABLE_TO: moment().add(1, 'weeks'),
    MEDIAS: [],
    PATROLLED: '',
    onReset: function () {
    }
};
