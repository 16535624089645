import React from 'react'
import NotificationSystem from 'react-notification-system'
import ajax from '../common/components/ajax.jsx'
import Constants from '../common/components/constants.jsx'
import cookie from 'react-cookie';
import md5 from "react-native-md5";
import {
    Button,
    Checkbox,
    Form,
    FormInput,
    FormField,
} from 'elemental-react16'
import 'sass/elemental.css'

import 'sass/login.scss'

export default class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            buttonState: '',
            _notificationSystem: null,
            component: 'loading',
            uid:      cookie.load('uid'),
            password: '',
            saveUid:  !!cookie.load('saveUid'),
        };
    }


    componentDidMount() {
        this.setState({
            _notificationSystem: this.refs.notificationSystem
        });
    }

    componentDidUpdate(prevProps, prevState){
        if(this.props.auth?.loggedIn){
            document.location.href = '/';
        }
    }


    addNotification(title, level, message, errorcode, uid) {
        this.state._notificationSystem.addNotification({
            title: title,
            message: message,
            level: level,
            position: 'tc',
            autoDismiss: 3,
            uid: uid
        });
    }


    handleSubmit(evt) {
        evt.preventDefault();

        const uid      = this.state.uid;
        const password = this.state.password;

        if(!uid || !password){
            const target = !uid? 'アカウントID' : 'パスワード';

            this.addNotification(
                'Error',
                'warning',
                target + 'を入力してください',
                0,
                0
            );
            return;
        }

        // IDを保存するにチェックが入っている場合はIDを、そうでなければ空白を Cookie に書き込む
        cookie.save('uid', this.state.saveUid? uid : '', { path: '/' });

        // IDを保存するのチェック状態を Cookie に書き込む
        cookie.save( 'saveUid', this.state.saveUid, { path: '/' });

        this.setState({buttonState: 'loading'});

        const loginPostData = {
            USER_ID:       uid,
            PASSWORD_HASH: md5.hex_md5(password),
        }

        ajax.post(
            Constants.URLs.LOGIN,
            loginPostData,
            (successJson) => {
                if(!successJson.hasOwnProperty('data')){
                    this.addNotification('Error', 'error', 'ログインに失敗しました。必要な情報が取得できませんでした。',　-1, -1);
                    return;
                }

                const data = successJson.data;
                console.log('Login Successed: ', data);

                const loggedIn    = data.hasOwnProperty('LOGGED_IN'   )? data.LOGGED_IN    : false;
                const userId      = data.hasOwnProperty('USER_ID'     )? data.USER_ID      : '';
                const displayName = data.hasOwnProperty('DISPLAY_NAME')? data.DISPLAY_NAME : '';
                const role        = data.hasOwnProperty('ROLE'        )? data.ROLE         : {};
                const roleName    = role.hasOwnProperty('NAME'        )? role.NAME         : '';
                const authorities = role.hasOwnProperty('AUTHORITIES' )? role.AUTHORITIES  : {};

                this.props.auth.onLoginStatusChange(loggedIn, userId, displayName, roleName, authorities);
            },
            (errorJson) => {
                console.log('Login Failed: ', errorJson);

                // すでにログインされている場合
                if(errorJson.httpStatus === 412){
                    this.forceLogout(successed => {
                        if(!successed){
                            this.addNotification('Error', 'error', errorJson.message, errorJson.code, errorJson.code);
                            return;
                        }

                        ajax.post(
                            Constants.URLs.LOGIN,
                            loginPostData,
                            (successJson) => {
                                if(!successJson.hasOwnProperty('data')){
                                    this.addNotification('Error', 'error', 'ログインに失敗しました。必要な情報が取得できませんでした。',　-1, -1);
                                    return;
                                }

                                const data = successJson.data;
                                console.log('Login Retry Successed: ', successJson.data);

                                const loggedIn    = data.hasOwnProperty('LOGGED_IN'   )? data.LOGGED_IN    : false;
                                const userId      = data.hasOwnProperty('USER_ID'     )? data.USER_ID      : '';
                                const displayName = data.hasOwnProperty('DISPLAY_NAME')? data.DISPLAY_NAME : '';
                                const role        = data.hasOwnProperty('ROLE'        )? data.ROLE         : {};
                                const roleName    = role.hasOwnProperty('NAME'        )? role.NAME         : '';
                                const authorities = role.hasOwnProperty('AUTHORITIES' )? role.AUTHORITIES  : {};

                                this.props.auth.onLoginStatusChange(loggedIn, userId, displayName, roleName, authorities);
                            },
                            (errorJson) => {
                                console.log('Login Retry Failed: ', errorJson);
                                if(errorJson.code !== 401){
                                    this.addNotification('Error', 'error', errorJson.message, errorJson.code, errorJson.code)
                                    return;
                                }
                            }
                        );
                    });
                    return;
                }

                if(errorJson.code !== 401){
                    this.addNotification('Error', 'error', errorJson.message, errorJson.code, errorJson.code);
                    return;
                }
            }
        )
    }


    forceLogout(handler){
        ajax.get(
            Constants.URLs.LOGOUT,
            (success) => {
                console.log('Logout successed.');
                this.props.auth.onLoginStatusChange(false);
                handler(true);
            },
            (error) => {
                console.log('Logout failed.');
                handler(false);
            },
        );
    }

    // ------------------------------------------------------------------------
    // onChange Handlers
    // ------------------------------------------------------------------------
    onChangeUid(val) {
        this.setState({ uid: val });
    }
    onChangePassword(val) {
        this.setState({ password: val });
    }
    onChangeSaveUid() {
        this.setState({ saveUid: !this.state.saveUid});
    }


    render() {
        return (
            <div>
                <div className='container clearfix login_box'>
                    <div className="form-signin">
                        <Form className="login_form">
                            <div className='top_logo' />

                            <FormField label="アカウントID">
                                <FormInput
                                    placeholder="アカウントIDを入力してください"
                                    required
                                    value={this.state.uid}
                                    onChange={(evt) => { this.onChangeUid(evt.currentTarget.value); }}/>
                            </FormField>

                            <FormField label="パスワード">
                                <FormInput
                                    placeholder="パスワードを入力してください"
                                    required
                                    type="password"
                                    value={this.state.password}
                                    onChange={(evt) => { this.onChangePassword(evt.currentTarget.value); }}/>
                            </FormField>

                            <Checkbox
                                label="IDを記憶する"
                                checked={this.state.saveUid}
                                onChange={() => { this.onChangeSaveUid(); }} />

                            <Button
                                submit={true}
                                type="hollow-primary"
                                onClick={(evt) => { this.handleSubmit(evt); }}
                                value={this.state.buttonState}
                            >
                                ログイン
                            </Button>
                        </Form>
                        <NotificationSystem ref="notificationSystem"/>
                    </div>
                </div>
                <NotificationSystem ref="notificationSystem"/>
            </div>
        );
    }
}

