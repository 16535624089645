
import ajax from '../../common/components/ajax.jsx'
import IstProgress from '../../common/components/progress.jsx'
import Constants from '../../common/components/constants.jsx'

import GlobalMenu from './global-menu.jsx'

import {
    Modal,
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from 'elemental-react16'
import 'sass/elemental.css'
import React from "react";
import {apiAccess} from "../../common/components/AxiosWrapper";


export default class App extends React.Component {
    'use strict';

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            logoutFailed: false,

            alertModalIsOpen: false,
            logoutModalIsOpen: false,

            alertTitle: "",
            alertBody: '',

            categories: [],

            test_code: '',
        };
    }

    componentDidMount(){
        this.loadCategoryList();
    }

    /**
     * 全カテゴリ取得APIと通信し、カテゴリの一覧を state に格納する
     */
    loadCategoryList =() =>{
        apiAccess.post(
            Constants.URLs.GET_ALL_CATEGORY,{USER_ID: this.props.auth.userId})
            .then((response) => {
                const successJson = response.data;
                const categories = successJson.hasOwnProperty('data') ? successJson.data : [];
                this.setState({
                    isLoading: false,
                    categories: categories,
                });
                console.log(categories)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    /**
     * アラートモーダルの表示状態を切り替える
     */
    toggleAlertModal() {
        // サインアウトに失敗した場合、アラートを閉じたときにスーパーリロードする
        if(this.state.logoutFailed) document.location.reload(true);

        this.setState({
            alertModalIsOpen: !this.state.alertModalIsOpen
        });
    }


    /**
     * ログアウトモーダルの表示状態を切り替える
     */
    toggleLogoutModal(){
        this.setState({
            logoutModalIsOpen: !this.state.logoutModalIsOpen
        });
    }


    /**
     * ログアウトAPIと通信し、成功した場合はログアウトする
     */
    doLogout(){
        ajax.post(
            Constants.URLs.LOGOUT,
            null,
            (successJson) => {
                if(successJson.hasOwnProperty('LOGGED_IN') && successJson.LOGGED_IN){
                    this.setState({
                        logoutFailed: true,
                        alertTitle: 'ログアウトエラー',
                        alertBody: 'ログアウトに失敗しました。ページを再読み込みします',
                        alertModalIsOpen: true
                    });
                    return;
                }

                this.props.auth.onLoginStatusChange(false);
                this.toggleLogoutModal();
                document.location.href = '/login';
            },
            (errorJson) => {
                this.toggleLogoutModal();
            }
        );
    }

    /**
     * 設定ページのURLを生成する
     */
    get settingUrl() {
        return `${document.location.protocol}//${document.location.host}/settings`;
    }


    render() {
        let main = (
            <div className="main-flex">
                <IstProgress />
            </div>
        );
        if(!this.state.isLoading){
            main = (
                <div className="main-flex">
                    <GlobalMenu
                        ref="menu"
                        menu={this.state.categories} />
                    <div id="contents_area">
                        {this.props.children && React.cloneElement(this.props.children, {
                            auth: this.props.auth,
                            categories: this.state.categories,
                            currentCategoryId: this.state.currentCategoryId,
                            loadCategoryList: () => { this.loadCategoryList(); }
                        })}
                    </div>
                </div>
            )
        }
        console.info('current user: ', this.props.auth);

        return (
            <div className="container">
                <div id="header_box">
                    <div id="logo"></div>
                    <div className="role"><p>ようこそ <i>{this.props.auth.displayName} ({this.props.auth.roleName})</i> さん</p></div>
                    <div className="logout_button" onClick={() => { this.toggleLogoutModal(); }}>ログアウト</div>
                    <div className="setting_button" onClick={() => { document.location.href = this.settingUrl; }}>設定</div>
                </div>
                {main}

                <Modal
                    width="small"
                    isOpen={this.state.alertModalIsOpen}
                    onCancel={() => { this.toggleAlertModal(); }}
                    backdropClosesModal
                >
                    <ModalHeader  showCloseButton onClose={() => { this.toggleAlertModal(); }}/>
                    <ModalBody>
                        {this.state.alertBody}
                    </ModalBody>
                </Modal>

                <Modal
                    width="medium"
                    isOpen={this.state.logoutModalIsOpen}
                    onCancel={() => { this.toggleLogoutModal(); }}
                    backdropClosesModal
                >
                    <ModalHeader
                        text="ログアウト"
                        showCloseButton
                        onClose={() => { this.toggleLogoutModal(); }}
                    />
                    <ModalBody>
                        ログアウトします。
                        よろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            type="primary"
                            onClick={() => { this.doLogout(); }}
                        >ログアウト</Button>

                        <Button
                            type="link-cancel"
                            onClick={() => { this.toggleLogoutModal(); }}
                        >キャンセル</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}
