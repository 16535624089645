import React from 'react'
import "moment/locale/ja";
import ajax from '../../common/components/ajax.jsx'
import Constants from '../../common/components/constants.jsx'
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormField,
    FormInput,
    FormSelect,
    Spinner
} from 'elemental-react16'



export default class Settings extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_SETTING') &&
            props.auth.authorities.EDIT_SETTING
        );

        this.state = {
            isLoading: true,

            editAuthorized: editAuthorized,

            SCROLL_START_TIME:  props.SCROLL_START_TIME,
            SCROLL_END_TIME:    props.SCROLL_END_TIME,
            SCROLL_SPEED:       props.SCROLL_SPEED,
            NO_SCROLL_END_TIME: props.NO_SCROLL_END_TIME,
            NO_TOPIC_END_TIME:  props.NO_TOPIC_END_TIME,

            // ユーザー投稿
            USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE:  props.USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE,

            // wall
            SNS_TOPIC_LIFETIME_DAYS: props.SNS_TOPIC_LIFETIME_DAYS,

            alertTitle: '',
            alertBody:  '',

            alertModalIsOpen: false,
            loadModalIsOpen:  false,
            resetModalIsOpen: false
        };
    }


    componentWillReceiveProps(props) {
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_SETTING') &&
            props.auth.authorities.EDIT_SETTING
        );

        this.setState({
            editAuthorized: editAuthorized,

            SCROLL_START_TIME:  props.SCROLL_START_TIME,
            SCROLL_END_TIME:    props.SCROLL_END_TIME,
            SCROLL_SPEED:       props.SCROLL_SPEED,
            NO_SCROLL_END_TIME: props.NO_SCROLL_END_TIME,
            NO_TOPIC_END_TIME:  props.NO_TOPIC_END_TIME,

            // ユーザー投稿
            USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE:  props.USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE,

            // wall
            SNS_TOPIC_LIFETIME_DAYS: props.SNS_TOPIC_LIFETIME_DAYS,
        });
    }

    componentWillMount() {
        this.getSettings();
    }


    /**
     * 通信してTopicsの一覧を取得し、
     * stateのitemsに全ての情報を格納します
     */
    getSettings() {
        ajax.post(
            Constants.URLs.GET_KS_SETTINGS,
            {USER_ID: this.props.auth.userId},
            (successJson) => {
                if(!successJson.hasOwnProperty('data')){
                    this.setState({isLoading: false});
                    return;
                }

                successJson.data.forEach(function(val){
                    switch(val.KEY_NAME){
                        case 'SCROLL_START_TIME':
                            this.setState({SCROLL_START_TIME: val.VALUE});
                            break;
                        case 'SCROLL_END_TIME':
                            this.setState({SCROLL_END_TIME: val.VALUE});
                            break;
                        case 'SCROLL_SPEED':
                            this.setState({SCROLL_SPEED: val.VALUE});
                            break;
                        case 'NO_SCROLL_END_TIME':
                            this.setState({NO_SCROLL_END_TIME: val.VALUE});
                            break;
                        case 'NO_TOPIC_END_TIME':
                            this.setState({NO_TOPIC_END_TIME: val.VALUE});
                            break;

                        // ユーザー投稿
                        case 'USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE':
                            this.setState({USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE: val.VALUE});
                            break;

                        // wall
                        case 'SNS_TOPIC_LIFETIME_DAYS':
                            this.setState({SNS_TOPIC_LIFETIME_DAYS: val.VALUE});
                            break;
                        default:
                    }
                }, this);

                this.setState({isLoading: false});
            },
            (errorJson) => {
                this.setState({
                    alertTitle:errorJson.response,
                    alertBody:errorJson.message + errorJson.code,
                    alertModalIsOpen:true
                });
            }
        );
    }




    toggleResetModal() {
        this.setState({
            resetModalIsOpen: !this.state.resetModalIsOpen
        });
    }

    toggleAlertModal() {
        this.setState({
            alertModalIsOpen: !this.state.alertModalIsOpen
        });
    }

    toggleLoadModal() {
        this.setState({
            loadModalIsOpen: !this.state.loadModalIsOpen
        });
    }


    postNewSettings() {
        if(!this.state.editAuthorized) return;

        if([
            this.state.SCROLL_START_TIME,
            this.state.SCROLL_END_TIME,
            this.state.SCROLL_SPEED,
            this.state.NO_SCROLL_END_TIME,
            this.state.NO_TOPIC_END_TIME,
            this.state.USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE,
        ].indexOf(null) !== -1) return

        let postData = {
            USER_ID: this.props.auth.userId,

            SCROLL_START_TIME:  this.state.SCROLL_START_TIME,
            SCROLL_END_TIME:    this.state.SCROLL_END_TIME,
            SCROLL_SPEED:       this.state.SCROLL_SPEED,
            NO_SCROLL_END_TIME: this.state.NO_SCROLL_END_TIME,
            NO_TOPIC_END_TIME:  this.state.NO_TOPIC_END_TIME,

            // ユーザー投稿
            USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE:  this.state.USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE,
        };

        // wall
        if(this.state.SNS_TOPIC_LIFETIME_DAYS !== null){
            postData.SNS_TOPIC_LIFETIME_DAYS = this.state.SNS_TOPIC_LIFETIME_DAYS;
        }

        this.toggleLoadModal();
        ajax.post(
            Constants.URLs.EDIT_KS_SETTINGS,
            postData,
            (successJson)=> {
                this.toggleLoadModal();
            },
            (errorJson)=> {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                })
                this.toggleLoadModal();
            }
        );
    }


    postResetSettings() {
        this.toggleResetModal();
        this.toggleLoadModal();

        const postData = {
            USER_ID: this.props.auth.userId,

            SCROLL_START_TIME:  10000,
            SCROLL_END_TIME:    10000,
            SCROLL_SPEED:       30,
            NO_SCROLL_END_TIME: 20000,
            NO_TOPIC_END_TIME:  10000
        };

        this.setState(postData);

        ajax.post(
            Constants.URLs.EDIT_KS_SETTINGS,
            postData,
            (successJson) => {
                this.toggleLoadModal();
            },
            (errorJson) => {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                })
                this.toggleLoadModal();
            }
        );
    }

    onUpdateScrollSpeed(val) {
        if(!this.state.editAuthorized) return;
        this.setState({SCROLL_SPEED: val});
    }

    onUpdateNoScrollEndTime(val) {
        if(!this.state.editAuthorized) return;
        this.setState({NO_SCROLL_END_TIME: val});
    }

    onUpdateNoTopicEndTime(val) {
        if(!this.state.editAuthorized) return;
        this.setState({NO_TOPIC_END_TIME: val});
    }

    onUpdateScrollStartTime(val) {
        if(!this.state.editAuthorized) return;
        this.setState({SCROLL_START_TIME: val});
    }

    onUpdateScrollEndTime(val) {
        if(!this.state.editAuthorized) return;
        this.setState({SCROLL_END_TIME: val});
    }

    onSelectedPatrolledStatus(val) {
        if(!this.state.editAuthorized) return;
        this.setState({USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE: val});
    }

    onUpdateSNSTopicLifetimeDays(val) {
        if(!this.state.editAuthorized) return;
        this.setState({SNS_TOPIC_LIFETIME_DAYS: val});
    }


    render() {
        if(this.state.isLoading){
            return (
                <div id="Category">
                    <h2>設定</h2>
                    <Spinner size="lg" />
                </div>
            );
        }

        // wallにおける設定値
        // この設定値のすべてが指定済み (nullではない) の場合、wallの設定値も設定画面に表示する
        let wallOptions = [
            this.state.SNS_TOPIC_LIFETIME_DAYS
        ];

        let wallSetting;
        if(wallOptions.indexOf(null) === -1){
            wallSetting = (
                <FormField label="SNSから収集したトピックのデフォルトの掲載日数">
                    <FormInput
                        disabled={!this.state.editAuthorized}
                        type="number"
                        value={this.state.SNS_TOPIC_LIFETIME_DAYS}
                        onChange={(evt) => { this.onUpdateSNSTopicLifetimeDays(evt.currentTarget.value); }} />
                </FormField>
            );
        }

        return (
            <div id="Category">
                <div className="header_prefix"></div>
                <h2>設定</h2>

                <Form component="form">
                    <FormField label="スクロール速度（ミリ秒 ※1/1000秒）">
                        <FormInput
                            disabled={!this.state.editAuthorized}
                            required
                            type="number"
                            value={this.state.SCROLL_SPEED}
                            onChange={(evt) => { this.onUpdateScrollSpeed(evt.currentTarget.value); }} />
                    </FormField>

                    <FormField label="スクロールが発生しないコンテンツの遷移までの待ち時間（ミリ秒 ※1/1000秒）">
                        <FormInput
                            disabled={!this.state.editAuthorized}
                            required
                            type="number"
                            value={this.state.NO_SCROLL_END_TIME}
                            onChange={(evt) => { this.onUpdateNoScrollEndTime(evt.currentTarget.value); }} />
                    </FormField>

                    <FormField label="トピックのないカテゴリが次のカテゴリに遷移するまでの待ち時間（ミリ秒 ※1/1000秒）">
                        <FormInput
                            disabled={!this.state.editAuthorized}
                            required
                            type="number"
                            value={this.state.NO_TOPIC_END_TIME}
                            onChange={(evt) => { this.onUpdateNoTopicEndTime(evt.currentTarget.value); }} />
                    </FormField>

                    <FormField label="トピックが表示されてスクロールが開始されるまでの待ち時間（ミリ秒 ※1/1000秒）">
                        <FormInput
                            disabled={!this.state.editAuthorized}
                            required
                            type="number"
                            value={this.state.SCROLL_START_TIME}
                            onChange={(evt) => { this.onUpdateScrollStartTime(evt.currentTarget.value); }} />
                    </FormField>

                    <FormField label="トピックのスクロールが終了してから、次のトピックに遷移するまでの待ち時間（ミリ秒 ※1/1000秒）">
                        <FormInput
                            disabled={!this.state.editAuthorized}
                            required
                            type="number"
                            value={this.state.SCROLL_END_TIME}
                            onChange={(evt) => { this.onUpdateScrollEndTime(evt.currentTarget.value); }} />
                    </FormField>

                    <FormSelect
                        disabled={!this.state.editAuthorized}
                        label="トピックがユーザーによって投稿されたとき、承認状態をデフォルトで次の値にする..."
                        options={[
                            {value: 'NOTYET',        label: '未確認'},
                            {value: 'AUTO_ACCEPTED', label: '自動承認'},
                            {value: 'REJECTED',      label: '却下'},
                        ]}
                        firstOption='承認状態を選択'
                        onChange={(val) => { this.onSelectedPatrolledStatus(val); }}
                        value={this.state.USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE} />

                    {wallSetting}
                </Form>

                <Button
                    disabled={!this.state.editAuthorized}
                    type="hollow-primary"
                    onClick={() => { this.postNewSettings(); }}
                >保存</Button>

                <Button
                    disabled={!this.state.editAuthorized}
                    type="link-cancel"
                    onClick={() => { this.toggleResetModal(); }}
                >初期化</Button>

                <Modal width="large" isOpen={this.state.resetModalIsOpen} onCancel={() => { this.toggleResetModal(); }}
                       backdropClosesModal>
                    <ModalHeader text="待機時間設定の初期化" showCloseButton onClose={this.toggleResetModal}/>
                    <ModalBody>
                        全ての設定の初期化を行います。
                        よろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <Button type="primary" onClick={this.postResetSettings}>初期化</Button>
                        <Button type="link-cancel" onClick={this.toggleResetModal}>キャンセル</Button>
                    </ModalFooter>
                </Modal>


                <Modal width="small"
                       isOpen={this.state.loadModalIsOpen}
                       onCancel={() => { this.toggleLoadModal(); }}
                       backdropClosesModal>
                    <ModalBody>
                        <Spinner size="lg" type="primary" />
                    </ModalBody>
                </Modal>

                <Modal
                    width="small"
                    isOpen={this.state.alertModalIsOpen}
                    onCancel={() => { this.toggleAlertModal(); }}
                    backdropClosesModal>

                    <ModalHeader text={this.state.alertTitle} showCloseButton onClose={() => { this.toggleAlertModal(); }} />
                    <ModalBody>
                        {this.state.alertBody}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


Settings.defaultProps = {
    role: {},

    SCROLL_START_TIME:  10000,
    SCROLL_END_TIME:    10000,
    SCROLL_SPEED:       30,
    NO_SCROLL_END_TIME: 20000,
    NO_TOPIC_END_TIME:  10000,

    // ユーザー投稿
    USER_CONTRIBUTED_TOPICS_PATROLLED_STATUS_DEFAULT_VALUE: 'NOTYET',

    // wall
    SNS_TOPIC_LIFETIME_DAYS: 7
};
