export default class Constants{

    static get DateFormats() {
        return {
            short: 'YYYY/MM/DD HH:mm',
            long:  'YYYY/MM/DD (ddd) HH:mm:ss',
            mysql: 'YYYY-MM-DD HH:mm:ss',
        };
    };

    static get URLs(){
            const API_VERSION = 'v2';
            const CLIENT_HOST    = process.env.NODE_ENV === "production" ? this.generateApiDomain() : "";
            const OAUTH_HOST = process.env.NODE_ENV === "production" ? this.generateApiDomain() : process.env.REACT_APP_API_DOMAIN;
            const API_HOST    = `${CLIENT_HOST}/api/${API_VERSION}/wall`;

        return {
            LOGIN:             `${API_HOST}/login`,
            LOGOUT:            `${API_HOST}/logout`,
            GET_ALL_CATEGORY:  `${API_HOST}/get-all-category`,
            GET_TOPICS:        `${API_HOST}/get-topics`,
            CREATE_TOPIC:      `${API_HOST}/create-topic`,
            EDIT_TOPIC:        `${API_HOST}/edit-topic`,
            DELETE_TOPIC:      `${API_HOST}/delete-topic`,
            FETCH_TOPIC_ORDER: `${API_HOST}/fetch-topic-order`,
            CREATE_CATEGORY:   `${API_HOST}/create-category`,
            EDIT_CATEGORY:     `${API_HOST}/edit-category`,
            DELETE_CATEGORY:   `${API_HOST}/delete-category`,
            GET_KS_SETTINGS:   `${API_HOST}/get-ks-settings`,
            EDIT_KS_SETTINGS:  `${API_HOST}/edit-ks-settings`,
            PATROL_TOPIC:      `${API_HOST}/patrol-topic`,
            CLIENT_HOST:       `${CLIENT_HOST}`,

            COLLECT_YOUTUBE_WITH_LINKED_CHANNEL: `${API_HOST}/cron_jobs/collect-youtube-with-linked-channels`,
            GET_YOUTUBE_CHANNEL: `${API_HOST}/get-youtube-channels`,
            ADD_YOUTUBE_CHANNEL: `${API_HOST}/add-youtube-channel`,
            ENABLE_YOUTUBE_CHANNEL: `${API_HOST}/enable-youtube-channel`,
            DISABLE_YOUTUBE_CHANNEL: `${API_HOST}/disable-youtube-channel`,

            OAUTH_FACEBOOK_PAGES:  `${OAUTH_HOST}/api/${API_VERSION}/wall/oauth-facebook/oauth`,
            COLLECT_FACEBOOK_WITH_LINKED_PAGES: `${API_HOST}/cron_jobs/collect-facebook-with-linked-pages`,
            GET_FACEBOOK_PAGES: `${API_HOST}/get-facebook-pages`,
            ENABLE_FACEBOOK_PAGES: `${API_HOST}/enable-facebook-page`,
            DISABLE_FACEBOOK_PAGES: `${API_HOST}/disable-facebook-page`,
            REFRESH_FACEBOOK_TOKENS: `${API_HOST}/cron_jobs/refresh-facebook-tokens`,

            COLLECT_TWITTER_WITH_LINKED_ACCOUNTS: `${API_HOST}/cron_jobs/collect-twitter-with-linked-accounts`,
            GET_TWITTER_ACCOUNT: `${API_HOST}/get-twitter-accounts`,
            ADD_TWITTER_ACCOUNT: `${API_HOST}/add-twitter-account`,
            ENABLE_TWITTER_ACCOUNT: `${API_HOST}/enable-twitter-account`,
            DISABLE_TWITTER_ACCOUNT: `${API_HOST}/disable-twitter-account`,
        };
    };

    /**
     * API用のドメインを自身のホスト名から生成
     * @returns {string}
     */
    static  generateApiDomain = (openWithBrowser) => {
        function changeApi(match, p1, p2, p3, offset, string) {
            return `${p1}-api${p3}`;
        }
        const hostname = window.location.hostname;
        const regex = /^(.*?)(-admin|-wall)(.*?\..*?)$/;
        if (hostname === "localhost") {
            return !openWithBrowser ? "" : process.env.REACT_APP_API_DOMAIN;
        } else {
            return `https://${hostname.replace(regex, changeApi)}`;
        }
    };

}