import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Facebook from "./Facebook";
import Twitter from "./Twitter";
import YouTube from "./YouTube";
import Instagram from "./Instagram";
import {useHistory} from "react-router";
import {useParams} from "react-router-dom";

SnsAuth.propTypes = {

};

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);
const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: '#2e1534',
    },
}));
function SnsAuth(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    let { slug } = useParams();
    let history = useHistory();

    console.log(slug)

    useEffect(() => {
        console.log(slug)
        switch(slug){
            case "twitter":
                setValue(0);
                break;
            case "facebook":
                setValue(1);
                break;
            case "youtube":
                setValue(2);
                break;
            case "instagram":
                setValue(3);
                break;
            default:
                console.log(slug)
                break;
        }
    }, [slug]);


    const handleChange = (event, newValue) => {
        console.log(newValue)
        setValue(newValue);
        let nextSlug = "";
        switch(newValue){
            case 0:
                nextSlug = "twitter";
                break;
            case 1:
                nextSlug = "facebook"
                break;
            case 2:
                nextSlug = "youtube";
                break;
            case 3:
                nextSlug = "instagram";
                break;
        }
        history.push("/snsauth/" + nextSlug)


    };

    return (
        <div id="content_wrapper">
            <div >
                <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                    <AntTab label="Twitter" />
                    <AntTab label="Facebook" />
                    <AntTab label="YouTube" />
                </AntTabs>
                <Typography className={classes.padding} />
            </div>
            <div>
                {value === 0 && <Twitter {...props}/>}
                {value === 1 && <Facebook {...props}/>}
                {value === 2 && <YouTube {...props}/>}
            </div>
        </div>
    );
}

export default SnsAuth;