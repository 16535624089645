import React from 'react';
import PropTypes from 'prop-types';

Instagram.propTypes = {
    
};

function Instagram(props) {
    return (
        <div>Instagram</div>
    );
}

export default Instagram;