import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {apiAccess} from "../../../common/components/AxiosWrapper";
import Constants from "../../../common/components/constants";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";

import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Spinner from "../Spinner/Spinner";
import TableBody from "@material-ui/core/TableBody";
import {
    Button, Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton, makeStyles,
    Modal, TextField
} from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

YouTube.propTypes = {};


function YouTube(props) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEnableOpen, setEnableModalParam] = useState(false);
    const [isDisableOpen, setDisableModalParam] = useState(false);
    const [isAlertOpen, setAlertModalParam] = useState(false);
    const [isCreateOpen, setCreateModalParam] = useState(false);
    const [contentId, setContentId] = useState(null)
    const [channelIdInput,setChannelIdInput] = useState("")

    const [errorMessage,setErrorMessage] = useState("");


    useEffect(() => {
        loadFacebookList();
    }, [])
    /**
     * Facebookの読み込み　
     */
    const loadFacebookList = () => {
        setLoading(true)
        apiAccess.get(
            Constants.URLs.GET_YOUTUBE_CHANNEL)
            .then((response) => {
                const successJson = response.data;
                setLoading(false)
                setList(successJson?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const toggleItemChange = (bool) => {
        apiAccess.post(
            bool ? Constants.URLs.ENABLE_YOUTUBE_CHANNEL : Constants.URLs.DISABLE_YOUTUBE_CHANNEL, {
                "USER_ID": props.auth.userId,
                "linked_youtube_channel_id": contentId
            })
            .then((response) => {
                const successJson = response.data;
                setLoading(false);
                toggleEnableModal(false)
                toggleDisableModal(false)
                setList(successJson?.data);
            })
            .catch((error) => {
                console.log(error);
                setErrorMessage(error?.response?.data?.message ? error.response.data.message : "不明なエラーが発生しました");
                setAlertModalParam(true)
            });
    }

    const createItem = () => {
        apiAccess.post(
            Constants.URLs.ADD_YOUTUBE_CHANNEL, {
                "YOUTUBE_CHANNEL_ID": channelIdInput
            })
            .then((response) => {
                const successJson = response.data;
                setLoading(false);
                toggleCreateModal(false);
                setList(successJson?.data);
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response)
                setErrorMessage(error?.response?.data?.message ? error.response.data.message : "不明なエラーが発生しました");
                setAlertModalParam(true)
            });
    }


    const toggleEnableModal = (bool) => {
        setEnableModalParam(bool)
    }
    const toggleDisableModal = (bool) => {
        setDisableModalParam(bool)
    }
    const toggleCreateModal = (bool) => {
        setCreateModalParam(bool)
    }
    const toggleAlertModal = (bool) =>{
        setAlertModalParam(bool)
    }

    return (
        <div>
            <div className={"console_wrapper"}>
                <Button color={"primary"} variant="contained" onClick={()=>toggleCreateModal(true)}>新規登録</Button>
            </div>
            <TableContainer component={Paper}>
                {loading ?
                    <Spinner/> :
                    <Table aria-label="youtube list table">
                        <TableHead>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>詳細</TableCell>
                                <TableCell className={"table_cell_datetime"}>追加日</TableCell>
                                <TableCell className={"table_cell_enable_check"} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {value.linked_youtube_channel_title}
                                    </TableCell>
                                    <TableCell>{value.linked_youtube_channel_description}</TableCell>
                                    <TableCell>{value.linked_youtube_channel_linked_datetime}</TableCell>
                                    <TableCell align={"right"} >
                                        {value.linked_youtube_channel_enabled ?
                                            <IconButton aria-label="delete" color="primary" onClick={() => {
                                                setContentId(value.linked_youtube_channel_id)
                                                toggleDisableModal(true);
                                            }}>
                                                <CheckBoxIcon/>
                                            </IconButton>
                                            :
                                            <IconButton aria-label="delete" color="primary" onClick={() => {
                                                setContentId(value.linked_youtube_channel_id)
                                                toggleEnableModal(true);
                                            }}>
                                                <CheckBoxOutlineBlankIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </TableContainer>


            <Dialog
                open={isCreateOpen}
                onClose={() => toggleCreateModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"新規追加"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>YouTubeチャンネルIDを入力してください</DialogContentText>
                    <TextField
                        id="outlined-youtube-channel-id-input"
                        label="YouTubeチャンネルID"
                        type="text"
                        fullWidth
                        autoComplete="current-password"
                        variant="outlined"
                        value={channelIdInput}
                        onChange={(e)=>{setChannelIdInput(e.target.value)}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button size={"large"}  onClick={() => toggleCreateModal(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button size={"large"} 　variant="contained"
                            onClick={()=>{
                                channelIdInput && createItem();
                            }}
                            color="primary">
                        追加
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={isAlertOpen}
                onClose={() => toggleAlertModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"エラー"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleAlertModal(false)} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDisableOpen}
                onClose={() => toggleDisableModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"無効化の確認"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>選択したYouTubeチャンネルからの情報取得を無効にしますが、よろしいですか</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleDisableModal(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button variant="contained" onClick={() => toggleItemChange(false)} color="primary">
                        無効にする
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isEnableOpen}
                onClose={() => toggleEnableModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle >{"有効化の確認"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>選択したYouTubeチャンネルからの情報取得を有効にしますが、よろしいですか</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleEnableModal(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button onClick={() => toggleItemChange(true)} color="primary"　variant="contained">
                        有効にする
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default YouTube;