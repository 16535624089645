import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {apiAccess} from "../../../common/components/AxiosWrapper";
import Constants from "../../../common/components/constants";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Paper from '@material-ui/core/Paper';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, IconButton,
    TextField
} from "@material-ui/core";
import Spinner from "../Spinner/Spinner";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";

Facebook.propTypes = {};

function Facebook(props) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEnableOpen, setEnableModalParam] = useState(false);
    const [isDisableOpen, setDisableModalParam] = useState(false);
    const [isAlertOpen, setAlertModalParam] = useState(false);
    const [isCreateOpen, setCreateModalParam] = useState(false);
    const [contentId, setContentId] = useState(null)
    const [channelIdInput, setChannelIdInput] = useState("")
    const [errorMessage, setErrorMessage] = useState("");


    useEffect(() => {
        loadFacebookList();
    }, [])
    /**
     * Facebookの読み込み　
     */
    const loadFacebookList = () => {
        setLoading(true)
        apiAccess.get(
            Constants.URLs.GET_FACEBOOK_PAGES)
            .then((response) => {
                const successJson = response.data;
                setLoading(false)
                setList(successJson?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const toggleItemChange = (bool) => {
        apiAccess.post(
            bool ? Constants.URLs.ENABLE_FACEBOOK_PAGES : Constants.URLs.DISABLE_FACEBOOK_PAGES, {
                "USER_ID": props.auth.userId,
                "linked_facebook_page_id": contentId
            })
            .then((response) => {
                const successJson = response.data;
                setLoading(false);
                toggleEnableModal(false)
                toggleDisableModal(false)
                setList(successJson?.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getOAuth = () => {
        apiAccess.get(
            Constants.URLs.OAUTH_FACEBOOK_PAGES, {
                "linked_facebook_page_id": channelIdInput
            })
            .then((response) => {
                const successJson = response.data;
                setLoading(false);
                toggleCreateModal(false);
                setList(successJson?.data);
            })
            .catch((error) => {
                console.log(error);
                console.log(error.response)
                setErrorMessage(error?.response?.data?.message ? error.response.data.message : "不明なエラーが発生しました");
                setAlertModalParam(true)
            });
    }


    const toggleEnableModal = (bool) => {
        setEnableModalParam(bool)
    }
    const toggleDisableModal = (bool) => {
        setDisableModalParam(bool)
    }
    const toggleCreateModal = (bool) => {
        setCreateModalParam(bool)
    }
    const toggleAlertModal = (bool) => {
        setAlertModalParam(bool)
    }

    return (
        <div>
            <div className={"console_wrapper"}>
                <Button color={"primary"} variant="contained" onClick={() => toggleCreateModal(true)}>新規登録</Button>
            </div>
            <TableContainer component={Paper}>

                {loading ?
                    <Spinner/> :
                    <Table aria-label="facebook list table">
                        <TableHead>
                            <TableRow>
                                <TableCell>名前</TableCell>
                                <TableCell>カテゴリ</TableCell>
                                <TableCell className={"table_cell_datetime"}>追加日</TableCell>
                                <TableCell className={"table_cell_datetime"}>有効期限</TableCell>
                                <TableCell className={"table_cell_enable_check"}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((value, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {value.linked_facebook_page_name}
                                    </TableCell>
                                    <TableCell>{value.linked_facebook_page_category}</TableCell>
                                    <TableCell>{value.linked_facebook_page_add_datetime}</TableCell>
                                    <TableCell>{value.linked_facebook_page_display_expire_time}</TableCell>
                                    <TableCell align={"right"}>
                                        {value.linked_facebook_page_enabled ?
                                            <IconButton aria-label="delete" color="primary" onClick={() => {
                                                setContentId(value.linked_facebook_page_id)
                                                toggleDisableModal(true);
                                            }}>
                                                <CheckBoxIcon/>
                                            </IconButton>
                                            :
                                            <IconButton aria-label="delete" color="primary" onClick={() => {
                                                setContentId(value.linked_facebook_page_id)
                                                toggleEnableModal(true);
                                            }}>
                                                <CheckBoxOutlineBlankIcon/>
                                            </IconButton>
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </TableContainer>

            <Dialog
                open={isCreateOpen}
                onClose={() => toggleCreateModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"新規追加"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        FacebookページのOAuth処理を行います。<br/>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button size={"large"} onClick={() => toggleCreateModal(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button size={"large"} variant="contained"
                            onClick={() => {
                                window.location.href = Constants.URLs.OAUTH_FACEBOOK_PAGES;
                            }}
                            color="primary">
                        OAuth
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isAlertOpen}
                onClose={() => toggleAlertModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"エラー"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{errorMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => toggleAlertModal(false)} color="primary">
                        閉じる
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={isDisableOpen}
                onClose={() => toggleDisableModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"無効化の確認"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>選択したFacebookページからの情報取得を無効にしますが、よろしいですか</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleDisableModal(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button variant="contained" onClick={() => toggleItemChange(false)} color="primary">
                        無効にする
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isEnableOpen}
                onClose={() => toggleEnableModal(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <DialogTitle>{"有効化の確認"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>選択したFacebookページからの情報取得を有効にしますが、よろしいですか</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => toggleEnableModal(false)} color="primary">
                        キャンセル
                    </Button>
                    <Button variant="contained" onClick={() => toggleItemChange(true)} color="primary">
                        有効にする
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default Facebook;