import React from 'react'

import 'sass/categories.scss'
import Constants from "../../common/components/constants";


export default class CategoryRow extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_CATEGORY') &&
            props.auth.authorities.EDIT_CATEGORY
        );

        const deleteAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('DELETE_CATEGORY') &&
            props.auth.authorities.DELETE_CATEGORY
        );

        this.state = {
            items: props.items,
            editAuthorized: editAuthorized,
            deleteAuthorized: deleteAuthorized,
        };
    }


    componentWillReceiveProps(props) {
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_CATEGORY') &&
            props.auth.authorities.EDIT_CATEGORY
        );

        const deleteAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('DELETE_CATEGORY') &&
            props.auth.authorities.DELETE_CATEGORY
        );

        this.setState({
            items: props.items,
            editAuthorized: editAuthorized,
            deleteAuthorized: deleteAuthorized,
        });
    }


    handleStart(evt) { // Dragging started
        console.log(evt);
    }
    handleEnd(evt) { // Dragging ended
        console.log(evt);
        console.log(this.state.items);
        this.updateSortItems();
    }
    handleAdd(evt) { // Element is dropped into the list from another list
        console.log(evt);
    }
    handleUpdate(evt) { // Changed sorting within list
        console.log(evt);
    }
    handleRemove(evt) { // Element is removed from the list into another list
        console.log(evt);
    }
    handleSort(evt) { // Called by any change to the list (add / update / remove)
        console.log(evt);
    }
    handleFilter(evt) { // Attempt to drag a filtered element
        console.log(evt);
    }
    handleMove(evt) { // Event when you move an item in the list or between lists
        console.log(evt);
    }

    updateSortItems() {
        this.props.updateSortItems(this.state.items);
    }

    editCategory(item) {
        this.props.editCategory(item);
    }
    deleteCategory(item) {
        this.props.deleteCategory(item);
    }


    render() {
        const items = this.state.items.map((item, index) => {
            const editDisabled   = (item.IS_SPECIAL || !this.state.editAuthorized);
            const deleteDisabled = (item.IS_SPECIAL || !this.state.deleteAuthorized);

            const edit_th = (<th className={'edit' + (editDisabled? ' disabled' : '')} onClick={() => {
                if(editDisabled) return;
                this.editCategory(item);
            }}><p><i></i>編集</p></th>);

            const delete_th = (<th className={'delete' + (deleteDisabled? ' disabled' : '')} onClick={() => {
                if(deleteDisabled) return;
                this.deleteCategory(item);
            }}><p><i></i>削除</p></th>);

            return (
                <tr key={index}>
                    <th>{item.CATEGORY_ID}</th>
                    <th>{item.CATEGORY_NAME}</th>
                    {/*<th>{<a href={Constants.URLs.CLIENT_HOST + item.CATEGORY_URL} target="_blank" className="category_url">{item.CATEGORY_URL}</a>}</th>*/}
                    <th></th>
                    {editDisabled ? <th>編集不可</th>:  edit_th}
                    {deleteDisabled ? <th>削除不可</th>:  delete_th}
                </tr>
            );
        });

        return (
            <tbody ref="list" className="block_list_category">
                {items}
            </tbody>
        );
    }
}

CategoryRow.defaultProps = {
    items: [],
};
