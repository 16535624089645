import React from 'react'
import ajax from '../../common/components/ajax.jsx'
import Constants from '../../common/components/constants.jsx'
import {apiAccess} from "./AxiosWrapper";

export default class Authentication extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {
            loggedIn:    false,
            userId:      '',
            displayName: '',
            roleName:    '',
            authorities: {},
        };
    }

    UNSAFE_componentWillMount() {
        ajax.post(
            Constants.URLs.LOGIN,
            null,
            (successJson) => {},
            (errorJson) => {
                // すでにログインされています以外のエラーとき
                if(errorJson.httpStatus !== 412){
                    if(document.location.pathname.indexOf('/login') !== 0){
                        document.location.href = '/login';
                    }
                    return;
                }
                const data = errorJson.data;
                console.log('I have session.', data);

                const loggedIn    = data.hasOwnProperty('LOGGED_IN'   )? data.LOGGED_IN    : false;
                const userId      = data.hasOwnProperty('USER_ID'     )? data.USER_ID      : '';
                const displayName = data.hasOwnProperty('DISPLAY_NAME')? data.DISPLAY_NAME : '';
                const role        = data.hasOwnProperty('ROLE'        )? data.ROLE         : {};
                const roleName    = role.hasOwnProperty('NAME'        )? role.NAME         : '';
                const authorities = role.hasOwnProperty('AUTHORITIES' )? role.AUTHORITIES  : {};

                this.onLoginStatusChange(loggedIn, userId, displayName, roleName, authorities);
            }
        );
    }


    onLoginStatusChange(loggedIn, userId, displayName, roleName, authorities){
        if(!loggedIn){
            userId      = '';
            displayName = '';
            roleName    = '';
            authorities = {};
        }
        this.setState({
            loggedIn:    loggedIn,
            userId:      userId,
            displayName: displayName,
            roleName:    roleName,
            authorities: authorities,
        });
    }


    render() {
        let auth = {
            loggedIn:    this.state.loggedIn,
            userId:      this.state.userId,
            displayName: this.state.displayName,
            roleName:    this.state.roleName,
            authorities: this.state.authorities,
            onLoginStatusChange: (loggedIn, userId, displayName, roleName, authorities) => {
                this.onLoginStatusChange(loggedIn, userId, displayName, roleName, authorities);
            },
        };

        return (
            <div>
                {this.props.children && React.cloneElement(this.props.children, {
                    auth: auth,
                })}
            </div>
        );
    }
}
