import React from 'react'
import {Button} from 'elemental-react16'
import { Link } from 'react-router-dom'


export default class Main extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {};
        console.log(props.test);
    }


    render() {
        return (
            <div id="default_message">
                <div id="Main">
                    <div className="lead">左サイドバーよりカテゴリを選択してください</div>
                    <Button type="hollow-primary"><Link to="/category">カテゴリを編集する</Link></Button>
                </div>
            </div>
        );
    }
}
