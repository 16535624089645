import React from 'react'
import { Link } from 'react-router-dom'

export default class GlobalMenu extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {
            menu: props.menu,
            role: props.role,
        };
    }


    componentWillReceiveProps(props) {
        console.dir(props);
        this.setState({
            menu: props.menu,
            role: props.role,
        });
    }

    /**
     * カテゴリ一覧のURLを生成する
     */
    get categoriesUrl() {
        return `/category`;
    }


    /**
     * トピック追加・編集ページのURLを生成する
     * @param  Number id カテゴリID
     */
    topicsUrl(id) {
        return `/topics/${id}`;
    }


    render() {
        const liArray = this.state.menu.map((list) => {
            const id   = list.CATEGORY_ID;
            const name = list.CATEGORY_NAME;

            return (
                <li key={id}>
                    <Link to={this.topicsUrl(id)}>{name}</Link>
                </li>
            );
        });

        return (
            <div id="global_menu">
                <div className="section"><Link to={this.categoriesUrl}>カテゴリ</Link></div>
                <div className="section"><Link to={"/snsauth"}>SNS連携設定</Link></div>
                <div className="menu_list">
                    <ul>
                        {liArray}
                    </ul>
                </div>
            </div>
        );
    }
}

GlobalMenu.defaultProps = {
    role: {},
    menu: []
};
