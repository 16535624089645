import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from "@material-ui/core";

Spinner.propTypes = {

};

function Spinner(props) {
    return (
        <div className={"progress_wrapper"}>
            <CircularProgress/>
        </div>
    );
}

export default Spinner;