import React from 'react'
import  {Table} from 'elemental-react16'
import CategoryRow from './category-row'
import "moment/locale/ja";
import ajax from '../../common/components/ajax.jsx'
import Constants from '../../common/components/constants.jsx'

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormInput,
    FormField,
    Spinner,
} from 'elemental-react16'



export default class Category extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        const createAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('CREATE_CATEGORY') &&
            props.auth.authorities.CREATE_CATEGORY
        );
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_CATEGORY') &&
            props.auth.authorities.EDIT_CATEGORY
        );
        const deleteAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('DELETE_CATEGORY') &&
            props.auth.authorities.DELETE_CATEGORY
        );

        this.state = {
            isLoading: false,
            items: props.categories,

            createAuthorized: createAuthorized,
            editAuthorized:   editAuthorized,
            deleteAuthorized: deleteAuthorized,

            createModalIsOpen: false,
            editModalIsOpen:   false,
            alertModalIsOpen:  false,
            loadModalIsOpen:   false,

            categories:        props.categories,
            constants_category_select_form: [],

            alertTitle: '',
            alertBody:  '',

            NEW_CATEGORY_ID:         props.NEW_CATEGORY_ID,
            NEW_CATEGORY_NAME:       props.NEW_CATEGORY_NAME,
            NEW_CATEGORY_IS_SPECIAL: props.NEW_CATEGORY_IS_SPECIAL,

            EDIT_CATEGORY_ID:         props.EDIT_CATEGORY_ID,
            EDIT_CATEGORY_NAME:       props.EDIT_CATEGORY_NAME,
            EDIT_CATEGORY_IS_SPECIAL: props.EDIT_CATEGORY_IS_SPECIAL,

            DELETE_CATEGORY_NAME:       props.DELETE_CATEGORY_NAME,
            DELETE_CATEGORY_ID:         props.DELETE_CATEGORY_ID,
            DELETE_CATEGORY_IS_SPECIAL: props.DELETE_CATEGORY_IS_SPECIAL,
        };
    }

    componentWillReceiveProps(props) {
        const createAuthorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('CREATE_CATEGORY') &&
            this.props.auth.authorities.CREATE_CATEGORY
        );
        const editAuthorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('EDIT_CATEGORY') &&
            this.props.auth.authorities.EDIT_CATEGORY
        );
        const deleteAuthorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('DELETE_CATEGORY') &&
            this.props.auth.authorities.DELETE_CATEGORY
        );

        this.setState({
            createAuthorized: createAuthorized,
            editAuthorized:   editAuthorized,
            deleteAuthorized: deleteAuthorized,

            categories: props.categories,
            items: props.categories
        });
    }

    componentDidUpdate(){
        console.log('update!', this.props.currentCategoryId);
    }


    // ------------------------------------------------------------------------
    // 汎用モーダルの状態遷移
    // ------------------------------------------------------------------------
    toggleAlertModal(){
        this.setState({alertModalIsOpen: !this.state.alertModalIsOpen});
    }

    toggleLoadModal(){
        this.setState({loadModalIsOpen: !this.state.loadModalIsOpen});
    }


    // ------------------------------------------------------------------------
    // カテゴリ新規作成
    // ------------------------------------------------------------------------
    toggleCreateModal() {
        this.setState({createModalIsOpen: !this.state.createModalIsOpen});
    }

    onChangeNewTitle(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_CATEGORY_NAME: val});
    }

    onSubmitCreateCategory() {
        if(!this.state.createAuthorized) return;
        if(!this.state.NEW_CATEGORY_NAME){
            this.setState({
                alertTitle:       '入力チェックエラー',
                alertBody:        'カテゴリ名を入力してください',
                alertModalIsOpen: true
            });
            return;
        }
        this.postCreateCategory();
    }

    /**
     * カテゴリ追加の送信
     */
    postCreateCategory() {
        this.toggleLoadModal();

        const postData = {
            USER_ID: this.props.auth.userId,
            CATEGORY_NAME: this.state.NEW_CATEGORY_NAME
        };
        console.log(postData);

        ajax.post(
            Constants.URLs.CREATE_CATEGORY,
            postData,
            (successJson) => {
                this.toggleLoadModal();
                this.toggleCreateModal();
                this.props.loadCategoryList();
            },
            (errorJson) => {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                });
                this.toggleLoadModal();
            }
        );
    }


    // ------------------------------------------------------------------------
    // カテゴリ編集
    // ------------------------------------------------------------------------
    toggleEditModal() {
        this.setState({editModalIsOpen: !this.state.editModalIsOpen});
    }

    onChangeEditTitle(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_CATEGORY_NAME: val});
    }

    editCategory(item) {
        if(!this.state.editAuthorized) return;

        this.setState({
            EDIT_CATEGORY_ID:         item.CATEGORY_ID,
            EDIT_CATEGORY_NAME:       item.CATEGORY_NAME,
            EDIT_CATEGORY_IS_SPECIAL: item.SPECIAL_CATEGORY
        });
        this.toggleEditModal();
    }

    onSubmitEditCategory() {
        if(!this.state.editAuthorized) return;
        if(this.state.EDIT_CATEGORY_IS_SPECIAL){
            this.setState({
                alertTitle:       '入力チェックエラー',
                alertBody:        '特別カテゴリを編集することはできません',
                alertModalIsOpen: true
            });
            return;
        }
        if(!this.state.EDIT_CATEGORY_ID){
            this.setState({
                alertTitle:       '入力チェックエラー',
                alertBody:        'カテゴリを選択してください',
                alertModalIsOpen: true
            });
            return;
        }
        if(!this.state.EDIT_CATEGORY_NAME){
            this.setState({
                alertTitle:       '入力チェックエラー',
                alertBody:        'カテゴリ名を入力してください',
                alertModalIsOpen: true
            });
            return;
        }
        this.postEditCategory();
    }

    /**
     * カテゴリ編集の通信
     */
    postEditCategory() {
        this.toggleLoadModal();
        if(this.state.EDIT_CATEGORY_IS_SPECIAL){
            this.setState({
                alertTitle: 'エラー',
                alertBody: '特別カテゴリを編集することはできません',
                alertModalIsOpen: true
            });
            this.toggleLoadModal();
            return;
        }

        const postData = {
            USER_ID: this.props.auth.userId,
            CATEGORY_ID:   this.state.EDIT_CATEGORY_ID,
            CATEGORY_NAME: this.state.EDIT_CATEGORY_NAME
        };
        console.log(postData);

        ajax.post(
            Constants.URLs.EDIT_CATEGORY,
            postData,
            (successJson) => {
                console.log(successJson);

                this.toggleLoadModal();
                this.toggleEditModal();
                this.props.loadCategoryList();
            },
            (errorJson) => {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                });
                this.toggleLoadModal();
            }
        );
    }


    // ------------------------------------------------------------------------
    // カテゴリ削除
    // ------------------------------------------------------------------------
    toggleDeleteModal(){
        this.setState({deleteModalIsOpen: !this.state.deleteModalIsOpen});
    }

    deleteCategory(item) {
        if(!this.state.deleteAuthorized) return;

        this.setState({
            DELETE_CATEGORY_ID:         item.CATEGORY_ID,
            DELETE_CATEGORY_NAME:       item.CATEGORY_NAME,
            DELETE_CATEGORY_IS_SPECIAL: item.SPECIAL_CATEGORY
        });
        this.toggleDeleteModal();
    }

    onSubmitDeleteCategory() {
        if(!this.state.deleteAuthorized) return;
        if(this.state.EDIT_CATEGORY_IS_SPECIAL){
            this.setState({
                alertTitle:       'エラー',
                alertBody:        '特別カテゴリを削除することはできません',
                alertModalIsOpen: true
            });
            return;
        }
        this.postDeleteCategory();
    }

    /**
     * カテゴリ削除の通信
     */
    postDeleteCategory() {
        this.toggleLoadModal();
        if(this.state.DELETE_CATEGORY_IS_SPECIAL){
            this.setState({
                alertTitle: 'エラー',
                alertBody: '特別カテゴリを削除することはできません',
                alertModalIsOpen: true
            });
            this.toggleLoadModal();
            return;
        }

        const postData = {
            USER_ID: this.props.auth.userId,
            CATEGORY_ID: this.state.DELETE_CATEGORY_ID
        };
        console.log(postData);

        ajax.post(
            Constants.URLs.DELETE_CATEGORY,
            postData,
            (successJson) => {
                console.log(successJson);

                this.toggleLoadModal();
                this.toggleDeleteModal();
                this.props.loadCategoryList();
            },
            (errorJson) => {
                this.setState({
                    alertTitle:errorJson.response,
                    alertBody:errorJson.message + errorJson.code,
                    alertModalIsOpen:true
                });
                this.toggleLoadModal();
            }
        );
    }

    updateSortItems(items) {
        this.setState({items: items});
    }

    render() {
        if(this.state.isLoading){
            return (
                <div id="Category">
                    <div className="header_prefix"></div>
                    <h2>カテゴリ一覧</h2>
                    <Spinner size="lg" />
                </div>
            );
        }

        return (
            <div id="Category">
                <div className="header_prefix"></div>
                <h2>カテゴリ一覧</h2>
                <Button
                    disabled={!this.state.createAuthorized}
                    type="hollow-primary"
                    style={{
                        width:  '250px',
                        margin: '10px 0'
                    }}
                    onClick={() => { this.toggleCreateModal(); }}
                >新規カテゴリの追加</Button>
                <Table>
                    <colgroup>
                        <col width="50"/>
                        <col width="40%"/>
                        <col width=""/>
                        <col width="80"/>
                        <col width="80"/>
                    </colgroup>
                    <thead><tr>
                        <th>ID</th><th>カテゴリ名</th><th>  </th><th></th><th></th>
                    </tr></thead>
                    <CategoryRow
                        items={this.state.items}
                        auth={this.props.auth}
                        editCategory={(items) => { this.editCategory(items); }}
                        updateSortItems={(items) => { this.updateSortItems(items); }}
                        deleteCategory={(item) => { this.deleteCategory(item); }} />
                </Table>

                <Modal width="large" isOpen={this.state.createModalIsOpen} onCancel={() => { this.toggleCreateModal(); }} backdropClosesModal>
                    <ModalHeader text="新規カテゴリの追加" showCloseButton onClose={() => { this.toggleCreateModal(); }} />
                    <ModalBody>
                        <Form component="form">
                           <FormField label="新規カテゴリ名" htmlFor="supported-controls-input">
                                <FormInput
                                    disabled={!this.state.createAuthorized}
                                    placeholder="カテゴリ名を入力してください"
                                    onChange={(evt) => { this.onChangeNewTitle(evt.currentTarget.value); }} />
                            </FormField>
                       </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={!this.state.createAuthorized}
                            type="primary"
                            onClick={(evt) => { this.onSubmitCreateCategory(); }}
                        >追加</Button>
                        <Button
                            type="link-cancel"
                            onClick={() => { this.toggleCreateModal(); }}
                        >キャンセル</Button>
                    </ModalFooter>
                </Modal>

                <Modal width="large" isOpen={this.state.editModalIsOpen} onCancel={() => { this.toggleEditModal(); }}
                       backdropClosesModal>
                    <ModalHeader text="カテゴリ名の編集" showCloseButton onClose={() => { this.toggleEditModal(); }}/>
                    <ModalBody>
                        <Form component="form">
                            <FormField label="変更カテゴリ名" htmlFor="supported-controls-input">
                                <FormInput
                                    disabled={!this.state.editAuthorized}
                                    placeholder="変更するカテゴリ名を入力してください"
                                    value={this.state.EDIT_CATEGORY_NAME}
                                    onChange={(evt) => { this.onChangeEditTitle(evt.currentTarget.value); }} />
                            </FormField>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={!this.state.editAuthorized}
                            type="primary"
                            onClick={(evt) => { this.onSubmitEditCategory(); }}
                        >保存</Button>
                        <Button
                            type="link-cancel"
                            onClick={() => { this.toggleEditModal(); }}
                        >キャンセル</Button>
                    </ModalFooter>
                </Modal>


                <Modal width="large" isOpen={this.state.deleteModalIsOpen} onCancel={() => { this.toggleDeleteModal(); }} backdropClosesModal>
                    <ModalHeader text="カテゴリを削除します" showCloseButton onClose={() => { this.toggleDeleteModal(); }}/>
                    <ModalBody>
                        カテゴリ「{this.state.DELETE_CATEGORY_NAME}」を削除します。
                        よろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={!this.state.deleteAuthorized}
                            type="primary"
                            onClick={() => { this.onSubmitDeleteCategory(); }}
                        >削除</Button>
                        <Button
                            type="link-cancel"
                            onClick={() => { this.toggleDeleteModal(); }}
                        >キャンセル</Button>
                    </ModalFooter>
                </Modal>

                <Modal width="small" isOpen={this.state.alertModalIsOpen} onCancel={() => { this.toggleAlertModal(); }} backdropClosesModal>
                    <ModalHeader text={this.state.alertTitle} showCloseButton onClose={() => { this.toggleAlertModal(); }}/>
                    <ModalBody>
                        {this.state.alertBody}
                    </ModalBody>
                </Modal>

                <Modal width="small" isOpen={this.state.loadModalIsOpen} onCancel={() => { this.toggleLoadModal(); }} backdropClosesModal>
                    <ModalBody>
                        <Spinner size="lg" type="primary" />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


Category.defaultProps = {
    role: {},
    NEW_TOPIC_IMAGE_ATTACH:   0,
    EDIT_TOPIC_IMAGE_ATTACH:  0,
    NEW_TOPIC_IMAGE_COMMENT:  '',
    EDIT_TOPIC_IMAGE_COMMENT: ''
};
