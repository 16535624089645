import React from 'react'

export default class IstProgress extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {
            message:     this.props.message     || '',
            spinnerName: this.props.spinnerName || 'three-bounce'
        }
    }


    render() {
        return(
            <div className='loading__progress__wrapper'>
                <div className='loading__message'>
                    <span>{this.state.message}</span>
                </div>
                <div className='loading__progress'>
                    <div spinnerName={this.state.spinnerName} />
                </div>
            </div>
        );
    }
}
