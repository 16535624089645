import axios from "axios";

const api_key = "DONT_PANIC";


const apiAccess = axios.create({
    baseURL : "/"
});

apiAccess.interceptors.request.use((config) => {
    config.headers = {'Content-Type':'application/json', "x-api-key": api_key,"Cache-Control": "no-cache"};
    config.timeout = 30000;
    config.responseType = 'json';
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

export { apiAccess };