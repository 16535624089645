
export default class ajax{
    static get API_KEY() {
        return 'DONT_PANIC';
    }

    static send = (xhr, data, success, error, sendToPubicSite) =>{
        if(!sendToPubicSite) {
            xhr.setRequestHeader('x-api-key', this.API_KEY);
            xhr.setRequestHeader('Content-Type', 'application/json');
        }
        xhr.onreadystatechange = function(){
            if(xhr.readyState !== 4) return;

            // エラーケース
            if(xhr.status < 200 || 400 <= xhr.status){
                let message, data;
                let code = xhr.status;
                try{
                    const json = JSON.parse(xhr.responseText);
                    message = json.message;
                    data    = json.data;
                    code    = json.code;
                }catch (e){}

                let response = {
                    'response':   'Error',
                    'code':       code,
                    'httpStatus': xhr.status,
                };

                if(typeof message !== 'undefined'){
                    if(xhr.status !== 412) console.error('Ajax Failed.', message);
                    response.message = message;
                }

                if(typeof data !== 'undefined'){
                    if(xhr.status !== 412) console.error('Ajax Failed.', data);
                    response.data = data;
                }

                error(response);
                return;
            }

            const json = JSON.parse(xhr.responseText)
            success(json);
        };
        xhr.send(data);
    }

    static get = (url, success, error, sendToPublicSite) =>{
        const xhr = new XMLHttpRequest();
        if(!sendToPublicSite){
            xhr.withCredentials = true;
        }
        xhr.open('GET', url, true);

        this.send(
            xhr,
            null,
            (json) => { success(json); },
            (json) => { error(json); },
            sendToPublicSite
        );
    }

    static post = (url, postData, success, error) => {
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open('POST', url, true);

        // json文字列にする
        if(postData !== null){
            postData = JSON.stringify(postData);
        }

        this.send(
            xhr,
            postData,
            (json) => { success(json); },
            (json) => { error(json); }
        );
    }
}
