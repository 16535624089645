import React from 'react'
import ReactDOM from 'react-dom'
import Category from 'main/components/category.jsx'
import Main from 'main/components/main.jsx'
import Topics from 'main/components/topics.jsx'
import Login from 'login/Login.js'
import Settings from 'main/components/settings.jsx'
import {IstError404} from 'common/components/error.jsx'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams
} from "react-router-dom";

import 'sass/signage.sass'
import 'elemental/less/elemental.less'
import App from "./main/components/app";
import Authentication from "./common/components/Authentication";
import SnsAuth from "./main/components/SnsAuth";
import "sass/app.scss"


ReactDOM.render(
    <Router>
        <Authentication>
            <RouteComponent />
        </Authentication>
    </Router>
    ,
    document.getElementById('root')
);


export function RouteComponent(props) {
    console.log(props)
    return (
        <Switch>
            <Route path="/login"><Login {...props}/></Route>
            <App {...props}>
                <ProtectedPage/>
            </App>
        </Switch>
    )
}


export function ProtectedPage(props){
    return (
        <Switch>
            <Route exact path={"/"}><Main {...props}/></Route>
            <Route path="/topics" exact><Topics {...props}/></Route>
            <Route path="/topics/:id"><Topics {...props}/></Route>
            <Route path="/category"><Category {...props}/></Route>
            <Route path="/settings"><Settings  {...props}/></Route>
            <Route path={"/snsauth/"} exact>
                <SnsAuth {...props}/>
            </Route>
            <Route path={"/snsauth/:slug"}>
                <SnsAuth {...props}/>
            </Route>
            <Route path="*"><IstError404 {...props}/></Route>
        </Switch>
    )
}