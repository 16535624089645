import React from 'react'
import moment from 'moment'
import 'moment/locale/ja'
import Constants from '../../common/components/constants.jsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'
import Sortable from 'react-sortablejs'

import {
    Glyph
} from 'elemental-react16'

import 'sass/topics.scss'

class TopicsRow extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
            showPatrol: props.showPatrol
        };
    }

    componentWillReceiveProps(props) {
        this.setState({
            items: props.items,
            showPatrol: props.showPatrol
        });
    }


    handleStart(evt) { // Dragging started
        console.log(evt);
    }
    handleEnd(evt) { // Dragging ended
        console.log(evt);
        console.log(this.state.items);
        this.updateSortItems();
    }
    handleAdd(evt) { // Element is dropped into the list from another list
        console.log(evt);
    }
    handleUpdate(evt) { // Changed sorting within list
        console.log(evt);
    }
    handleRemove(evt) { // Element is removed from the list into another list
        console.log(evt);
    }
    handleSort(evt) { // Called by any change to the list (add / update / remove)
        console.log(evt);
    }
    handleFilter(evt) { // Attempt to drag a filtered element
        console.log(evt);
    }
    handleMove(evt) { // Event when you move an item in the list or between lists
        console.log(evt);
    }

    updateSortItems() {
        console.log(this.state.items);
        this.props.updateSortItems(this.state.items);
    }

    editTopics(item) {
        this.props.editTopics(item);
    }
    deleteTopic(item) {
        this.props.deleteTopic(item);
    }
    patrolTopic(item){
        this.props.patrolTopic(item);
    }

    dateTimeComponent(str) {
        const parts = str.split(' ');
        return (
            <div className="date_time">
                <span className="date">{parts[0]}</span>
                <span className="time">{parts[1]}</span>
            </div>
        );
    }


    render() {
        const edit_autorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('EDIT_TOPIC') &&
            this.props.auth.authorities.EDIT_TOPIC
        );
        const delete_autorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('DELETE_TOPIC') &&
            this.props.auth.authorities.DELETE_TOPIC
        );
        const patrol_autorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('PATROL_TOPIC') &&
            this.props.auth.authorities.PATROL_TOPIC
        );

        const items = this.state.items.map((item, index) => {
            // 画像添付がある場合アイコンを追加
            let attach;
            if(item.MEDIAS.length) attach = (
                <div className="attach">
                    <FontAwesomeIcon size="2x" icon={faImage} className={"far"} color={"gray"}  />
                </div>);

            // 配信状態のステータス
            let castStatus;
            switch(item.CAST_STATUS){
                case 0:
                    castStatus = ( <span className="cast-status casting">配信中</span> );
                    break;
                case 1:
                    castStatus = ( <span className="cast-status castwaiting">配信前</span> );
                    break;
                case 2:
                    castStatus = ( <span className="cast-status castend">配信終了</span> );
                    break;
                default:
                    castStatus = ( <span className="cast-status castundefined">不明</span> );
            }

            // 検閲状態のステータス
            let patrolledString = '';
            if(this.state.showPatrol){
                switch(item.PATROLLED){
                    case 'NOTYET':        patrolledString = '未確認'; break;
                    case 'ACCEPTED':      patrolledString = '承認'; break;
                    case 'REJECTED':      patrolledString = '却下'; break;
                    case 'AUTO_ACCEPTED': patrolledString = '自動承認'; break;
                    default: patrolledString = '不明'
                }
            }
            const patrollStatus = patrolledString === ''? null : ( <span className={"patrol-status " + item.PATROLLED}>{patrolledString}</span> );

            let editTh;
            let deleteTh;

            if(edit_autorized){
                editTh = (<div className="edit" onClick={() => { this.editTopics(item); }}><p><i></i>編集</p></div>);
            }else if(patrol_autorized){
                editTh = (<div className="edit" onClick={() => { this.patrolTopic(item); }}><p><i></i>承認</p></div>);
            }

            if(delete_autorized){
                deleteTh = (<div className="delete" onClick={() => { this.deleteTopic(item); }}><p><i></i>削除</p></div>);
            }

            // リストオブジェクト
            return (
                <li key={index}>
                    <span className="index">{index+1}</span>
                    <p className="statuses">
                        {castStatus}
                        {patrollStatus}
                    </p>
                    <div className="main">
                        <p className="upper">
                            <span className="title">{item.TOPIC_TITLE}</span>
                        </p>
                        <p className="lower">
                            <span className="created">{moment(item.TOPIC_CREATED).format(Constants.DateFormats.short)}</span>
                            <span className="available">{
                                moment(item.TOPIC_AVAILABLE_FROM).format(Constants.DateFormats.short)
                            } から {
                                moment(item.TOPIC_AVAILABLE_TO).format(Constants.DateFormats.short)
                            }</span>
                        </p>
                    </div>
                    {attach}
                    {editTh}
                    {deleteTh}
                </li>
            );
        });

        return ( <ul className="topics-list">{items}</ul> );
    }
}

TopicsRow.defaultProps = {
    items: [],
    showPatrol: false,
};

const sortableOptions = {
    ref: 'list',
    model: 'items',
    animation: 150,
    ghostClass: "sortable-ghost",  // Class name for the drop placeholder
    chosenClass: "sortable-chosen"
};
export default Sortable(sortableOptions)(TopicsRow)
