import React from 'react'
import FontAwesome from 'react-fontawesome';
import {Button} from 'elemental-react16'
import { Link } from 'react-router-dom'

export class IstError extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {
            message:      this.props.message      || '',
            button_label: this.props.button_label || '戻る'
        }
    }


    render() {
        return (
            <div className='error__wrapper'>
                <FontAwesome name='fa fa-exclamation-triangle' size='3x' style={{ textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)'}} />
                <h3 className="form-confirm-heading">エラーが発生しました</h3>
                <div>
                    <h5>{this.state.message}</h5>
                </div>
                <div className="flex-box-horizontal-nowrap">
                    <div className="button_wrapper flex-col-auto" type='button' onClick={()=>{document.location.href='/do/menu'}}>
                        <button className="button">トップに戻る</button>
                    </div>
                </div>
            </div>
        );
    }
}


export class IstError404 extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        this.state = {
            message:      this.props.message      || '該当するページが見つかりませんでした',
            button_label: this.props.button_label || '戻る'
        }
    }

    render() {
        return (
            <div id="default_message">
                <div id="Main">
                    <div className="lead">{this.state.message}</div>
                    <Button type="hollow-primary" onClick={()=>{document.location.href='/'}}><Link to="/category">メニューに戻る</Link></Button>
                </div>
            </div>
        );
    }
}
