import React from 'react'
import moment from 'moment'
import 'moment/locale/ja'

import ajax from '../../common/components/ajax.jsx'
import Constants from '../../common/components/constants.jsx'

import TopicsRow from './topics-row'
import TopicForm from './topic-form'

import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Spinner,
} from 'elemental-react16'
import { withRouter } from "react-router-dom";

import 'sass/topics.scss'
import {isFullScreenCategory} from "./Utils";


class Topics extends React.Component {
    'use strict';

    constructor(props) {
        super(props);

        const createAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('CREATE_CATEGORY') &&
            props.auth.authorities.CREATE_CATEGORY
        );
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_CATEGORY') &&
            props.auth.authorities.EDIT_CATEGORY
        );
        const deleteAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('DELETE_CATEGORY') &&
            props.auth.authorities.DELETE_CATEGORY
        );
        const patrolAuthorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('PATROL_TOPIC') &&
            this.props.auth.authorities.PATROL_TOPIC
        );

        const currentCategoryId = props.match.params.hasOwnProperty('id')? parseInt( props.match.params.id, 10) : -1;
        let currentCategoryName = '';
        if(props.categories.length > 0){
            const hit = props.categories.filter((category) => {
                return category.CATEGORY_ID === currentCategoryId;
            });
            if(hit.length){
                currentCategoryName = hit[0]['CATEGORY_NAME'];
            }
        }

        this.state = {
            isLoading:true,
            items: props.items,

            createAuthorized: createAuthorized,
            editAuthorized:   editAuthorized,
            deleteAuthorized: deleteAuthorized,
            patrolAuthorized: patrolAuthorized,

            CATEGORY_ID:   currentCategoryId,
            CATEGORY_NAME: currentCategoryName,

            createModalIsOpen: false,
            editModalIsOpen: false,
            alertModalIsOpen: false,
            loadModalIsOpen: false,
            deleteModalIsOpen: false,
            categories: props.categories,
            alertTitle:'',
            alertBody:'',

            NEW_CATEGORY_ID:          currentCategoryId,
            NEW_TOPIC_AVAILABLE_FROM: moment(),
            NEW_TOPIC_AVAILABLE_TO:   moment().add(1, 'weeks'),
            NEW_TOPIC_TITLE:          '',
            NEW_TOPIC_BODY:           '',
            NEW_TOPIC_AUTHOR_NAME:    '',
            NEW_MEDIAS:               [],
            NEW_PATROLLED:            'ACCEPTED',

            EDIT_TOPIC_ID:             0,
            EDIT_CATEGORY_ID:          currentCategoryId,
            EDIT_TOPIC_AVAILABLE_FROM: props.EDIT_TOPIC_AVAILABLE_FROM,
            EDIT_TOPIC_AVAILABLE_TO:   props.EDIT_TOPIC_AVAILABLE_TO,
            EDIT_TOPIC_TITLE:          props.EDIT_TOPIC_TITLE,
            EDIT_TOPIC_BODY:           props.EDIT_TOPIC_BODY,
            EDIT_TOPIC_CREATED:        props.EDIT_TOPIC_CREATED,
            EDIT_TOPIC_AUTHOR_NAME:    props.EDIT_TOPIC_AUTHOR_NAME,
            EDIT_MEDIAS:               props.EDIT_MEDIAS,
            EDIT_TOPIC_SORT_NO:        props.EDIT_TOPIC_SORT_NO,
            EDIT_PATROLLED:            props.EDIT_PATROLLED,

            PATROL_TOPIC_ID:             0,
            PATROL_CATEGORY_ID:          currentCategoryId,
            PATROL_TOPIC_AVAILABLE_FROM: props.PATROL_TOPIC_AVAILABLE_FROM,
            PATROL_TOPIC_AVAILABLE_TO:   props.PATROL_TOPIC_AVAILABLE_TO,
            PATROL_TOPIC_TITLE:          props.PATROL_TOPIC_TITLE,
            PATROL_TOPIC_BODY:           props.PATROL_TOPIC_BODY,
            PATROL_TOPIC_CREATED:        props.PATROL_TOPIC_CREATED,
            PATROL_TOPIC_AUTHOR_NAME:    props.PATROL_TOPIC_AUTHOR_NAME,
            PATROL_MEDIAS:               props.PATROL_MEDIAS,
            PATROL_TOPIC_SORT_NO:        props.PATROL_TOPIC_SORT_NO,
            PATROL_PATROLLED:            props.PATROL_PATROLLED,
        };

        this.patrolledStatusOptions = [
            {value: 'NOTYET',        disabled: true, label: '未確認'},
            {value: 'AUTO_ACCEPTED', disabled: true, label: '自動承認'},
            {value: 'ACCEPTED',                      label: '承認'},
            {value: 'REJECTED',                      label: '却下'},
        ];

        console.log(props.match)
        console.log(props)
        console.log(currentCategoryId);
        this.getTopics(currentCategoryId);
    }


    componentWillReceiveProps(props) {
        const createAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('CREATE_CATEGORY') &&
            props.auth.authorities.CREATE_CATEGORY
        );
        const editAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('EDIT_CATEGORY') &&
            props.auth.authorities.EDIT_CATEGORY
        );
        const deleteAuthorized = (
            props.auth.hasOwnProperty('authorities') &&
            props.auth.authorities.hasOwnProperty('DELETE_CATEGORY') &&
            props.auth.authorities.DELETE_CATEGORY
        );
        const patrolAuthorized = (
            this.props.auth.hasOwnProperty('authorities') &&
            this.props.auth.authorities.hasOwnProperty('PATROL_TOPIC') &&
            this.props.auth.authorities.PATROL_TOPIC
        );
        console.log(props.match.params)
        const currentCategoryId =  props.match.params.hasOwnProperty('id')? parseInt(props.match.params.id, 10) : -1;

        let currentCategoryName = '';
        if(props.categories.length > 0){
            const hit = props.categories.filter((category) => {
                return category.CATEGORY_ID === currentCategoryId;
            });
            if(hit.length){
                currentCategoryName = hit[0]['CATEGORY_NAME'];
            }
        }

        this.setState({
            auth: props.auth,

            createAuthorized: createAuthorized,
            editAuthorized:   editAuthorized,
            deleteAuthorized: deleteAuthorized,
            patrolAuthorized: patrolAuthorized,

            isLoading: true,
            categories: props.categories,

            CATEGORY_ID:      currentCategoryId,
            NEW_CATEGORY_ID:  currentCategoryId,
            EDIT_CATEGORY_ID: currentCategoryId,
            CATEGORY_NAME:    currentCategoryName,
        });

        console.log("topics")
        console.log(currentCategoryId)
        this.getTopics(currentCategoryId);
    }

    componentDidUpdate(){
    }

    editCategory() {
        this.props.editCategory();
    }


    /**
     * 通信してTopicsの一覧を取得し、
     * stateのitemsに全ての情報を格納します
     */
    getTopics(id) {
        if(id === -1) return;

        ajax.post(
            Constants.URLs.GET_TOPICS,
            {
                USER_ID: this.props.auth.userId,
                CATEGORIES: [id]
            },
            (successJson)=> {
                this.setState({
                    items: successJson.data || [],
                    isLoading: false
                });
            },
            (errorJson)=> {
                this.setState({
                    items: [],
                    isLoading: false,
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                })
            }
        );
    }

    /**
     * トピックをソートする
     * @param  Object items 新しいソート順になったトピックの一覧
     */
    updateSortItems(items){
        this.setState({items:items});

        let topic_order = [];
        items.forEach((val) => {
            topic_order.push(Number(val.TOPIC_ID));
        });
        const postData = {
            USER_ID: this.props.auth.userId,
            TOPIC_ORDER: topic_order
        };

        ajax.post(
            Constants.URLs.FETCH_TOPIC_ORDER,
            postData,
            (successJson)=> {
                this.setState({'items': successJson.data});
            },
            (errorJson)=> {
                this.setState({
                    alertTitle:       'エラー',
                    alertBody:        'トピックの並び替えに失敗しました',
                    alertModalIsOpen: true
                });
                return;
            }
        );
    }

    // ------------------------------------------------------------------------
    // 汎用モーダルの状態遷移
    // ------------------------------------------------------------------------
    toggleAlertModal(){
        this.setState({alertModalIsOpen: !this.state.alertModalIsOpen});
    }

    toggleLoadModal(){
        this.setState({loadModalIsOpen: !this.state.loadModalIsOpen});
    }


    // ------------------------------------------------------------------------
    // トピック新規作成
    // ------------------------------------------------------------------------
    toggleCreateModal() {
        this.setState({createModalIsOpen: !this.state.createModalIsOpen});
    }

    /**
     * トピック新規作成用ステートの初期化
     */
    resetCreateTopics() {
        this.setState({
            NEW_CATEGORY_ID:          this.state.CATEGORY_ID,
            NEW_TOPIC_AVAILABLE_FROM: moment(),
            NEW_TOPIC_AVAILABLE_TO:   moment().add(1, 'weeks'),
            NEW_TOPIC_TITLE:          '',
            NEW_TOPIC_BODY:           '',
            NEW_TOPIC_AUTHOR_NAME:    '',
            NEW_MEDIAS:               [],
            NEW_PATROLLED:            'ACCEPTED',
        });
    }

    onSubmitNew(){
        if(!this.state.createAuthorized) return;

        let message = null;
        switch(true){
            case this.state.NEW_CATEGORY_ID === null || this.state.NEW_CATEGORY_ID === '':
                message = 'カテゴリを選択してください';
                break;

            case this.state.NEW_TOPIC_AVAILABLE_FROM === null || this.state.NEW_TOPIC_AVAILABLE_FROM === '':
                // FIXME: moment()型チェック
                message = '配信期間の開始日を選択してください';
                break;

            case this.state.NEW_TOPIC_AVAILABLE_TO === null || this.state.NEW_TOPIC_AVAILABLE_TO === '':
                // FIXME: moment()型チェック
                message = '配信期間の終了日を選択してください';
                break;

            case (
                (this.state.NEW_MEDIAS === null || this.state.NEW_MEDIAS.length === 0) &&
                (this.state.NEW_TOPIC_BODY === null || this.state.NEW_TOPIC_BODY === '') &&
                !isFullScreenCategory(this.state.categories, this.state.NEW_CATEGORY_ID)
            ):
                message = '記事の本文を入力してください';
                break;

            case this.state.NEW_TOPIC_AUTHOR_NAME === null || this.state.NEW_TOPIC_AUTHOR_NAME === '':
                message = '記事の配信元を入力してください';
                break;

            case (this.state.NEW_TOPIC_TITLE === null || this.state.NEW_TOPIC_TITLE === '') &&
            !isFullScreenCategory(this.state.categories, this.state.NEW_CATEGORY_ID):
                message = '記事のタイトルを入力してください';
                break;

            case typeof this.state.NEW_PATROLLED === 'undefined' || this.state.NEW_PATROLLED === null || this.state.NEW_PATROLLED === '':
                message = '承認状態を選択してください';
                break;

            case isFullScreenCategory(this.state.categories, this.state.NEW_CATEGORY_ID) && this.state.NEW_MEDIAS.length === 0:
                //フルスクリーンの場合の添付ファイル添付状態
                message = '添付ファイルが添付されていません';
                break;

            case typeof this.state.NEW_MEDIAS !== 'undefined' && this.state.NEW_MEDIAS.length > 0:
                this.state.NEW_MEDIAS.forEach((media) => {
                    if(typeof media.DISPLAY_SIZE === 'undefined' || ['LARGE', 'MEDIUM', 'SMALL'].indexOf(media.DISPLAY_SIZE) === -1){
                        message = 'メディアの表示サイズが指定されていません';
                        return;
                    }
                });
                break;

            default:
        }

        if(message !== null){
            this.setState({
                alertTitle:      '入力チェックエラー',
                alertBody:       message,
                alertModalIsOpen: true
            });
            return;
        }

        this.postCreateTopic();
    }

    /**
     * 通信してTopicsを追加
     */
    postCreateTopic() {
        if(!this.state.createAuthorized) return;

        const postData = {
            USER_ID: this.props.auth.userId,

            CATEGORY_ID:          this.state.NEW_CATEGORY_ID,
            TOPIC_TITLE:          this.state.NEW_TOPIC_TITLE,
            TOPIC_BODY:           this.state.NEW_TOPIC_BODY,
            TOPIC_AUTHOR_NAME:    this.state.NEW_TOPIC_AUTHOR_NAME,
            TOPIC_AVAILABLE_FROM: this.state.NEW_TOPIC_AVAILABLE_FROM.format('X'),
            TOPIC_AVAILABLE_TO:   this.state.NEW_TOPIC_AVAILABLE_TO.format('X'),
            MEDIAS:               this.state.NEW_MEDIAS,
            PATROLLED:            this.state.NEW_PATROLLED,
        };

        console.log('create topic', postData);

        this.toggleLoadModal();
        ajax.post(
            Constants.URLs.CREATE_TOPIC,
            postData,
            (successJson)=> {
                this.toggleLoadModal();
                this.toggleCreateModal()
                this.resetCreateTopics()

                this.getTopics(this.state.CATEGORY_ID);
            },
            (errorJson)=> {
                this.setState({
                    alertTitle:errorJson.response,
                    alertBody:errorJson.message + errorJson.code,
                    alertModalIsOpen:true
                })
                this.toggleLoadModal();
            }
        );
    }

    // ------------------------------------------------------------------------
    // トピック編集
    // ------------------------------------------------------------------------
    /**
     * 編集モーダルの状態遷移
     */
    toggleEditModal() {
        this.setState({editModalIsOpen: !this.state.editModalIsOpen});
    }

    /**
     * 編集モーダルを表示する
     * @param  object item 編集するトピック
     */
    showEditTopicModal(item){
        this.setState({
            EDIT_TOPIC_ID:             item.TOPIC_ID,
            EDIT_CATEGORY_ID:          item.CATEGORY_ID,
            EDIT_TOPIC_AVAILABLE_FROM: moment(item.TOPIC_AVAILABLE_FROM),
            EDIT_TOPIC_AVAILABLE_TO:   moment(item.TOPIC_AVAILABLE_TO),
            EDIT_TOPIC_TITLE:          item.TOPIC_TITLE,
            EDIT_TOPIC_BODY:           item.TOPIC_BODY,
            EDIT_TOPIC_CREATED:        moment(item.TOPIC_CREATED),
            EDIT_TOPIC_AUTHOR_NAME:    item.TOPIC_AUTHOR_NAME,
            EDIT_MEDIAS:               item.MEDIAS,
            EDIT_TOPIC_SORT_NO:        item.TOPIC_SORT_NO,
            EDIT_PATROLLED:            item.PATROLLED,
        });
        this.toggleEditModal();
    }

    onSubmitEdit(){
        if(!this.state.editAuthorized) return;

        let message = null;
        switch(true){
            case this.state.EDIT_CATEGORY_ID === null || this.state.EDIT_CATEGORY_ID === '':
                message = 'カテゴリを選択してください';
                break;

            case this.state.EDIT_TOPIC_AVAILABLE_FROM === null || this.state.EDIT_TOPIC_AVAILABLE_FROM === '':
                // FIXME: moment()型チェック
                message = '配信期間の開始日を選択してください';
                break;

            case this.state.EDIT_TOPIC_AVAILABLE_TO === null || this.state.EDIT_TOPIC_AVAILABLE_TO === '' :
                // FIXME: moment()型チェック
                message = '配信期間の終了日を選択してください';
                break;

            case (
                (this.state.EDIT_MEDIAS === null || this.state.EDIT_MEDIAS.length === 0) &&
                (this.state.EDIT_TOPIC_BODY === null || this.state.EDIT_TOPIC_BODY === '') &&
                !isFullScreenCategory(this.state.categories, this.state.EDIT_CATEGORY_ID)
            ):
                message = '記事の本文を入力してください';
                break;

            case this.state.EDIT_TOPIC_AUTHOR_NAME === null || this.state.EDIT_TOPIC_AUTHOR_NAME === '':
                message = '記事の配信元を入力してください';
                break;

            case (this.state.EDIT_TOPIC_TITLE === null || this.state.EDIT_TOPIC_TITLE === '') &&
            !isFullScreenCategory(this.state.categories, this.state.EDIT_CATEGORY_ID):
                message = '記事のタイトルを入力してください';
                break;

            case typeof this.state.EDIT_PATROLLED === 'undefined' || this.state.EDIT_PATROLLED === null || this.state.EDIT_PATROLLED === '':
                message = '承認状態を選択してください';
                break;


            case isFullScreenCategory(this.state.categories, this.state.EDIT_CATEGORY_ID) && this.state.EDIT_MEDIAS.length === 0:
                //フルスクリーンの場合の添付ファイル添付状態
                message = '添付ファイルが添付されていません';
                break;

            case typeof this.state.EDIT_MEDIAS !== 'undefined' && this.state.EDIT_MEDIAS.length > 0:
                this.state.NEW_MEDIAS.forEach((media) => {
                    if(typeof media.DISPLAY_SIZE === 'undefined' || ['LARGE', 'MEDIUM', 'SMALL'].indexOf(media.DISPLAY_SIZE) === -1){
                        message = 'メディアの表示サイズが指定されていません';
                        return;
                    }
                });
                break;

            default:
        }

        if(message !== null){
            this.setState({
                alertTitle:      '入力チェックエラー',
                alertBody:       message,
                alertModalIsOpen: true
            });
            return;
        }

        this.postEditTopic();
    }

    /**
     * 編集の送信
     */
    postEditTopic() {
        if(!this.state.editAuthorized) return;

        const postData = {
            USER_ID: this.props.auth.userId,

            TOPIC_ID :            this.state.EDIT_TOPIC_ID,
            CATEGORY_ID :         this.state.EDIT_CATEGORY_ID,
            TOPIC_TITLE:          this.state.EDIT_TOPIC_TITLE,
            TOPIC_BODY:           this.state.EDIT_TOPIC_BODY,
            TOPIC_AUTHOR_NAME:    this.state.EDIT_TOPIC_AUTHOR_NAME,
            TOPIC_AVAILABLE_FROM: this.state.EDIT_TOPIC_AVAILABLE_FROM.format('X'),
            TOPIC_AVAILABLE_TO:   this.state.EDIT_TOPIC_AVAILABLE_TO.format('X'),
            MEDIAS:               this.state.EDIT_MEDIAS,
            PATROLLED:            this.state.EDIT_PATROLLED
        };
        console.log('edit topic', postData);

        this.toggleLoadModal();
        ajax.post(
            Constants.URLs.EDIT_TOPIC,
            postData,
            (successJson)=> {
                this.toggleLoadModal();
                this.toggleEditModal()
                this.getTopics(this.state.CATEGORY_ID);
            },
            (errorJson)=> {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                })
                this.toggleLoadModal();
            }
        );
    }

    // ------------------------------------------------------------------------
    // トピック承認
    // ------------------------------------------------------------------------
    /**
     * 承認モーダルの状態遷移
     */
    togglePatrolModal() {
        this.setState({patrolModalIsOpen: !this.state.patrolModalIsOpen});
    }

    /**
     * 承認モーダルを表示する
     * @param  object item 編集するトピック
     */
    showPatrolTopicModal(item){
        this.setState({
            PATROL_TOPIC_ID:             item.TOPIC_ID,
            PATROL_CATEGORY_ID:          item.CATEGORY_ID,
            PATROL_TOPIC_AVAILABLE_FROM: moment(item.TOPIC_AVAILABLE_FROM),
            PATROL_TOPIC_AVAILABLE_TO:   moment(item.TOPIC_AVAILABLE_TO),
            PATROL_TOPIC_TITLE:          item.TOPIC_TITLE,
            PATROL_TOPIC_BODY:           item.TOPIC_BODY,
            PATROL_TOPIC_CREATED:        moment(item.TOPIC_CREATED),
            PATROL_TOPIC_AUTHOR_NAME:    item.TOPIC_AUTHOR_NAME,
            PATROL_MEDIAS:               item.MEDIAS,
            PATROL_TOPIC_SORT_NO:        item.TOPIC_SORT_NO,
            PATROL_PATROLLED:            item.PATROLLED,
        });
        this.togglePatrolModal();
    }

    onSubmitPatrol() {
        if(!this.state.patrolAuthorized) return;

        let message = null;
        switch(true){
            case typeof this.state.PATROL_PATROLLED === 'undefined' || this.state.PATROL_PATROLLED === null || this.state.PATROL_PATROLLED === '':
                message = '承認状態を選択してください';
                break;
            default:
        }

        if(message !== null){
            this.setState({
                alertTitle:       '入力チェックエラー',
                alertBody:        message,
                alertModalIsOpen: true
            });
            return;
        }

        this.postPatrolTopic();
    }

    /**
     * 承認の送信
     */
    postPatrolTopic() {
        if(!this.state.patrolAuthorized) return;

        const postData = {
            USER_ID: this.props.auth.userId,

            TOPIC_ID:  this.state.PATROL_TOPIC_ID,
            PATROLLED: this.state.PATROL_PATROLLED
        };
        console.log('patrol topic', postData);

        this.toggleLoadModal();
        ajax.post(
            Constants.URLs.PATROL_TOPIC,
            postData,
            (successJson)=> {
                this.toggleLoadModal();
                this.togglePatrolModal()
                this.getTopics(this.state.CATEGORY_ID);
            },
            (errorJson)=> {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                })
                this.toggleLoadModal();
            }
        );
    }

    // ------------------------------------------------------------------------
    // トピック削除
    // ------------------------------------------------------------------------
    /**
     * 削除モーダルの状態遷移
     */
    toggleDeleteModal(){
        this.setState({deleteModalIsOpen: !this.state.deleteModalIsOpen});
    }

    /**
     * 削除モーダルを表示する
     * @param  object item 削除するトピック
     */
    showDeleteTopicModal(item){
        if(!this.state.deleteAuthorized) return;

        this.setState({
            DELETE_TOPIC_ID:   item.TOPIC_ID,
            DELETE_TOPIC_NAME: item.TOPIC_TITLE
        });
        this.toggleDeleteModal();
    }

    /**
     * 削除の送信
     */
    postDeleteTopic(){
        if(!this.state.deleteAuthorized) return;

        this.toggleLoadModal();
        const postData = {
            USER_ID: this.props.auth.userId,
            TOPIC_ID: this.state.DELETE_TOPIC_ID
        };
        console.log('delete topic', postData);

        ajax.post(
            Constants.URLs.DELETE_TOPIC,
            postData,
            (successJson)=> {
                console.log(successJson);
                this.toggleLoadModal();
                this.toggleDeleteModal();
                this.getTopics(this.state.CATEGORY_ID);
            },
            (errorJson)=> {
                this.setState({
                    alertTitle: errorJson.response,
                    alertBody: errorJson.message + errorJson.code,
                    alertModalIsOpen: true
                })
                this.toggleLoadModal();
            }
        );
    }

    onDrop() {}

    onChangeNewTitle(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_TOPIC_TITLE: val});
    }
    onChangeNewAuthor(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_TOPIC_AUTHOR_NAME: val});
    }
    onChangeNewBody(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_TOPIC_BODY: val});
    }
    onChangeNewFrom(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_TOPIC_AVAILABLE_FROM: val});
    }
    onChangeNewTo(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_TOPIC_AVAILABLE_TO: val});
    }
    onChangeNewCategory(val) {
        if(!this.state.createAuthorized) return;
        val = parseInt(val, 10);
        this.setState({NEW_CATEGORY_ID: val});
    }
    onChangeNewPatrolled(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_PATROLLED: val});
    }
    onChangeNewMedias(val) {
        if(!this.state.createAuthorized) return;
        this.setState({NEW_MEDIAS: val});;
    }

    onChangeEditTitle(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_TOPIC_TITLE: val});
    }
    onChangeEditAuthor(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_TOPIC_AUTHOR_NAME: val});
    }
    onChangeEditBody(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_TOPIC_BODY: val});
    }
    onChangeEditFrom(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_TOPIC_AVAILABLE_FROM: val});
    }
    onChangeEditTo(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_TOPIC_AVAILABLE_TO: val});
    }
    onChangeEditCategory(val) {
        if(!this.state.editAuthorized) return;
        val = parseInt(val, 10);
        this.setState({EDIT_CATEGORY_ID: val});
    }
    onChangeEditPatrolled(val) {
        if(!this.state.editAuthorized) return;
        this.setState({EDIT_PATROLLED: val});
    }
    onChangeEditMedias(val) {
        if(!this.state.editAuthorized) return;
        console.log('onChangeEditMedias', val);
        this.setState({EDIT_MEDIAS: val});
    }

    onChangePatrolPatrolled(val){
        if(!this.state.editAuthorized && !this.state.patrolAuthorized) return;
        this.setState({PATROL_PATROLLED: val});
    }


    render() {
        const currentCategory = this.state.categories.filter((category) => {
            return category.CATEGORY_ID === this.state.CATEGORY_ID;
        })[0];

        //const isUserContributionsCategory = currentCategory.IS_SPECIAL && currentCategory.CATEGORY_NAME === 'ユーザー投稿';
        const isUserContributionsCategory = currentCategory?.IS_SPECIAL;

        if(this.state.isLoading) {
            return (
                <div id="Category">
                    <div className="header_prefix">トピック追加・編集</div>
                    <h2>カテゴリ：{this.state.CATEGORY_NAME}</h2>
                    <Spinner size="lg" />
                </div>
            );
        }

        return (
            <div id="Category">
                <div className="header_prefix">トピック追加・編集</div>
                <h2>カテゴリ：{this.state.CATEGORY_NAME}</h2>
                <Button
                    disabled={!this.state.createAuthorized}
                    type="hollow-primary"
                    style={{
                        width:  '250px',
                        margin: '10px 0'
                    }}
                    onClick={() => { this.toggleCreateModal(); }}
                >新規トピックの追加</Button>

                <TopicsRow
                    auth={this.props.auth}
                    items={this.state.items}
                    showPatrol={isUserContributionsCategory}
                    editTopics={(item) => { this.showEditTopicModal(item); }}
                    updateSortItems={(items) => { this.updateSortItems(items); }}
                    deleteTopic={(item) => { this.showDeleteTopicModal(item); }}
                    patrolTopic={(item) => { this.showPatrolTopicModal(item); }}/>

                <TopicForm
                    auth={this.props.auth}
                    isNew={true}
                    isOpen={this.state.createModalIsOpen}
                    categories={this.state.categories}

                    onSubmit={() => { this.onSubmitNew(); }}
                    toggleModal={() => { this.toggleCreateModal(); }}
                    onChangeTitle={(val) => { this.onChangeNewTitle(val); }}
                    onChangeAuthor={(val) => { this.onChangeNewAuthor(val); }}
                    onChangeBody={(val) => { this.onChangeNewBody(val); }}
                    onChangeFrom={(val) => { this.onChangeNewFrom(val); }}
                    onChangeTo={(val) => { this.onChangeNewTo(val); }}
                    onChangeCategory={(val) => { this.onChangeNewCategory(val); }}
                    onChangePatrolled={(val) => { this.onChangeNewPatrolled(val); }}
                    onChangeMedias={(val) => { this.onChangeNewMedias(val); }}
                    onReset={() => { this.resetCreateTopics(); }}
                    CATEGORY_ID={this.state.NEW_CATEGORY_ID}
                    TOPIC_TITLE={this.state.NEW_TOPIC_TITLE}
                    TOPIC_BODY={this.state.NEW_TOPIC_BODY}
                    TOPIC_AUTHOR_NAME={this.state.NEW_TOPIC_AUTHOR_NAME}
                    TOPIC_AVAILABLE_FROM={this.state.NEW_TOPIC_AVAILABLE_FROM}
                    TOPIC_AVAILABLE_TO={this.state.NEW_TOPIC_AVAILABLE_TO}
                    MEDIAS={this.state.NEW_MEDIAS}
                    PATROLLED={this.state.NEW_PATROLLED}
                    TOPIC_CREATED={null}/>

                <TopicForm
                    auth={this.props.auth}
                    isNew={false}
                    isOpen={this.state.editModalIsOpen}
                    categories={this.state.categories}

                    onSubmit={() => { this.onSubmitEdit(); }}
                    toggleModal={() => { this.toggleEditModal(); }}
                    onChangeTitle={(val) => { this.onChangeEditTitle(val); }}
                    onChangeAuthor={(val) => { this.onChangeEditAuthor(val); }}
                    onChangeBody={(val) => { this.onChangeEditBody(val); }}
                    onChangeFrom={(val) => { this.onChangeEditFrom(val); }}
                    onChangeTo={(val) => { this.onChangeEditTo(val); }}
                    onChangeCategory={(val) => { this.onChangeEditCategory(val); }}
                    onChangePatrolled={(val) => { this.onChangeEditPatrolled(val); }}
                    onChangeMedias={(val) => { this.onChangeEditMedias(val); }}
                    TOPIC_ID={this.state.EDIT_TOPIC_ID}
                    CATEGORY_ID={this.state.EDIT_CATEGORY_ID}
                    TOPIC_TITLE={this.state.EDIT_TOPIC_TITLE}
                    TOPIC_BODY={this.state.EDIT_TOPIC_BODY}
                    TOPIC_AUTHOR_NAME={this.state.EDIT_TOPIC_AUTHOR_NAME}
                    TOPIC_AVAILABLE_FROM={this.state.EDIT_TOPIC_AVAILABLE_FROM}
                    TOPIC_AVAILABLE_TO={this.state.EDIT_TOPIC_AVAILABLE_TO}
                    MEDIAS={this.state.EDIT_MEDIAS}
                    PATROLLED={this.state.EDIT_PATROLLED}
                    TOPIC_CREATED={this.state.EDIT_TOPIC_CREATED}/>

                <TopicForm
                    auth={this.props.auth}
                    isNew={false}
                    isOpen={this.state.patrolModalIsOpen}
                    categories={this.state.categories}

                    onSubmit={() => { this.onSubmitPatrol(); }}
                    toggleModal={() => { this.togglePatrolModal(); }}
                    onChangePatrolled={(val) => { this.onChangePatrolPatrolled(val); }}
                    TOPIC_ID={this.state.PATROL_TOPIC_ID}
                    CATEGORY_ID={this.state.PATROL_CATEGORY_ID}
                    TOPIC_TITLE={this.state.PATROL_TOPIC_TITLE}
                    TOPIC_BODY={this.state.PATROL_TOPIC_BODY}
                    TOPIC_AUTHOR_NAME={this.state.PATROL_TOPIC_AUTHOR_NAME}
                    TOPIC_AVAILABLE_FROM={this.state.PATROL_TOPIC_AVAILABLE_FROM}
                    TOPIC_AVAILABLE_TO={this.state.PATROL_TOPIC_AVAILABLE_TO}
                    MEDIAS={this.state.PATROL_MEDIAS}
                    PATROLLED={this.state.PATROL_PATROLLED}
                    TOPIC_CREATED={this.state.PATROL_TOPIC_CREATED}/>


                <Modal width="small" isOpen={this.state.alertModalIsOpen} onCancel={() => { this.toggleAlertModal(); }} backdropClosesModal>
                    <ModalHeader text={this.state.alertTitle} showCloseButton onClose={() => { this.toggleAlertModal(); }}/>
                    <ModalBody>{this.state.alertBody}</ModalBody>
                </Modal>


                <Modal width="large" isOpen={this.state.deleteModalIsOpen} onCancel={() => { this.toggleDeleteModal(); }} backdropClosesModal>
                    <ModalHeader text="トピックを削除します" showCloseButton onClose={() => { this.toggleDeleteModal(); }}/>
                    <ModalBody>
                        トピック「{this.state.DELETE_TOPIC_NAME}」を削除します。
                        よろしいですか？
                    </ModalBody>
                    <ModalFooter>
                        <Button type="primary" style={{width:"150px"}} onClick={() => { this.postDeleteTopic(); }}>削除</Button>
                        <Button type="link-cancel"  style={{width:"150px"}} onClick={() => { this.toggleDeleteModal(); }}>キャンセル</Button>
                    </ModalFooter>
                </Modal>


                <Modal width="small" isOpen={this.state.loadModalIsOpen} onCancel={() => { this.toggleLoadModal(); }} backdropClosesModal>
                    <ModalBody>
                        <Spinner size="lg" type="primary" />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


Topics.defaultProps = {
    CATEGORY_ID:   -1,
    CATEGORY_NAME: '',

    NEW_TOPIC_AVAILABLE_FROM:  moment(),
    NEW_TOPIC_AVAILABLE_TO:    moment().add(1, 'weeks'),
    EDIT_TOPIC_AVAILABLE_FROM: moment(),
    EDIT_TOPIC_AVAILABLE_TO:   moment().add(1, 'weeks'),
};


export default withRouter(Topics);